import React from 'react';
import '../../styles/additional/Contacts.scss';
import { useQuery } from 'react-query';
import { fetchTranslate } from '../../api/Api';

function Contacts() {

    // API options

    const dataTranslate = useQuery('translate', fetchTranslate);

    return (
        <div className="Contacts parent">
            <div className="wrapper">
                <h1 className="title">{dataTranslate?.data?.data["Contacts"]}</h1>
            </div>
        </div>
    )
}

export default Contacts
