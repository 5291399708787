import React from 'react';
import { useQuery } from 'react-query';
import { useLocation, useSearchParams } from 'react-router-dom';
import { fetchBranches, fetchTranslate } from '../../../api/Api';

function BranchInformations() {

    // router options

    const path = useLocation();
    const [param, setParam] = useSearchParams();

    // API options

    const dataTranslate = useQuery('translate', fetchTranslate);
    const dataBranches = useQuery(['branches', path.search], () => fetchBranches(path.search));

    return (
        <div className="BranchInformations parent gap-3">
            {param.get('region') ? dataBranches?.data?.data?.results?.filter((c) => c?.region?.id == param.get('region'))?.map((item) => (
                <div key={item?.id} className="infos gap-1 pd-2">
                    <p className="big-text"><b>{dataTranslate?.data?.data["Branch"]}:</b> {item?.name}</p>
                    <p className="big-text"><b>{dataTranslate?.data?.data["direktor"]}:</b> {item?.director}</p>
                    <p className="big-text"><b>{dataTranslate?.data?.data["Phone Number"]}:</b> {item?.phone}</p>
                    <p className="big-text"><b>{dataTranslate?.data?.data["Email"]}:</b> <a href={`mailto:${item?.email}`}>{item?.email}</a></p>
                    <p className="big-text"><b>{dataTranslate?.data?.data["Address"]}:</b> {item?.address}</p>
                    <p className="text">{item?.description}</p>
                    <div className='text' dangerouslySetInnerHTML={{ __html: item?.information }} />
                </div>
            ))
                :
                dataBranches?.data?.data?.map((item) => (
                    <div key={item?.id} className="infos gap-1 pd-2">
                        <p className="big-text"><b>{dataTranslate?.data?.data["Branch"]}:</b> {item?.name}</p>
                        <p className="big-text"><b>{dataTranslate?.data?.data["direktor"]}:</b> {item?.director}</p>
                        <p className="big-text"><b>{dataTranslate?.data?.data["Phone Number"]}:</b> {item?.phone}</p>
                        <p className="big-text"><b>{dataTranslate?.data?.data["Email"]}:</b> <a href={`mailto:${item?.email}`}>{item?.email}</a></p>
                        <p className="big-text"><b>{dataTranslate?.data?.data["Address"]}:</b> {item?.address}</p>
                        <p className="text">{item?.description}</p>
                        <div className='text' dangerouslySetInnerHTML={{ __html: item?.information }} />
                    </div>
                ))}
        </div>
    )
}

export default BranchInformations
