import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import '../../styles/modals/ContactModal.scss';
import { REQUESTS_URL } from '../../api/ApiUrl';
import { CloseIcon } from '../../assets/svgImages';
import { fetchSports, fetchTranslate } from '../../api/Api';

function ContactModal({ changeContactModal }) {

    // API options

    const dataSports = useQuery('sports', fetchSports);
    const dataTranslate = useQuery('translate', fetchTranslate);

    // form options

    const [selectedId, setSelectedId] = useState("");

    useEffect(() => {
        setSelectedId(dataSports?.data?.data[0]?.id);
    }, [dataSports?.isLoading]);

    function sendMessage(e) {
        e.preventDefault();
        changeContactModal();

        const formData = new FormData();
        formData.append('sport', e.target[2].value);
        formData.append('full_name', e.target[2].value);
        formData.append('phone_number', e.target[2].value);

        axios.post(REQUESTS_URL,
            {
                sport: selectedId,
                full_name: e.target[0].value,
                phone_number: e.target[1].value,
            })
            .then(function (res) {
                console.log(res);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    return (
        <div className="ContactModal">
            <div className="close_icon" onClick={() => changeContactModal()}><CloseIcon /></div>
            <form onSubmit={sendMessage} className="forms gap-4">
                <h1 className="title">{dataTranslate?.data?.data["submit"]}</h1>
                <input type="text" required className='input text pd-1' placeholder={`${dataTranslate?.data?.data["fio"]}`} />
                <input type="number" required className='input text pd-1' placeholder={`${dataTranslate?.data?.data["Phone Number"]}`} />
                <div className="selection pd-1 input">
                    <select className='text' value={selectedId} onChange={(e) => setSelectedId(e.target.value)}>
                        {dataSports?.data?.data?.map((item) => (
                            <option key={item?.id} value={item?.id}>{item?.name}</option>
                        ))}
                    </select>
                </div>
                <button type="submit" className='send_btn big-text scale-05'>{dataTranslate?.data?.data["send"]}</button>
            </form>
        </div>
    )
}

export default ContactModal
