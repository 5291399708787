import React from 'react';
import Pools from './branches/Pools';
import { useQuery } from 'react-query';
import Partners from '../home/Partners';
import Coaches from './branches/Coaches';
import '../../styles/additional/Diving.scss';
import Sportsmens from './branches/Sportsmens';
import LeaderShips from './branches/LeaderShips';
import BackImg from '../../assets/images/diving.webp';
import { fetchRegions, fetchTranslate } from '../../api/Api';
import BranchInformations from './branches/BranchInformations';
import { useParentContext } from '../../contexts/ParentContext';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AthletesIcon, CoachIcon, LeadershipIcon, PoolIcon, RulesIcon } from '../../assets/svgImages';

function Branches() {

    // router options

    const path = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();
    const [param, setParam] = useSearchParams();

    // API options

    const dataRegions = useQuery('regions', fetchRegions);
    const dataTranslate = useQuery('translate', fetchTranslate);

    // pages options

    function changePage(item) {
        navigate(`/${path.pathname.split('/').slice(1, 2)[0]}/${item}`);
    };

    // useContext hook options

    const { changeRegion } = useParentContext();

    return (
        <>
            <div className="Diving parent">
                <div className="wrapper c1">
                    <img src={BackImg} alt="back-img" className="back-img" />
                    <h1 className="title main_title">{dataTranslate?.data?.data["fly_text"]}</h1>
                </div>
                <div className="wrapper c2 gap-3">
                    <h1 style={{ textAlign: "center", fontWeight: "700" }} className="title">{dataTranslate?.data?.data["Branches"]}</h1>
                    <div className="selection">
                        <select className='name pd-1' value={param.get('region') ? param.get('region') : ""} onChange={(e) => changeRegion(e.target.value)}>
                            <option value="">{dataTranslate?.data?.data["regions"]}</option>
                            {dataRegions?.data?.data?.map((item) => (
                                <option key={item?.id} value={item?.id}>{item?.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="categories gap-1">
                        <div className={`category gap-1 round-07 pd-1 ${id == undefined && "active_category"}`} onClick={() => changePage("")}>
                            <div className="icon"><RulesIcon /></div>
                            <p className="text">{dataTranslate?.data?.data["Information"]}</p>
                        </div>
                        <div className={`category gap-1 round-07 pd-1 ${id == 2 && "active_category"}`} onClick={() => changePage(2)}>
                            <div className="icon"><AthletesIcon /></div>
                            <p className="text">{dataTranslate?.data?.data["Athletes"]}</p>
                        </div>
                        <div className={`category gap-1 round-07 pd-1 ${id == 3 && "active_category"}`} onClick={() => changePage(3)}>
                            <div className="icon"><CoachIcon /></div>
                            <p className="text">{dataTranslate?.data?.data["Coaches"]}</p>
                        </div>
                        <div className={`category gap-1 round-07 pd-1 ${id == 4 && "active_category"}`} onClick={() => changePage(4)}>
                            <div className="icon"><LeadershipIcon /></div>
                            <p className="text">{dataTranslate?.data?.data["management"]}</p>
                        </div>
                        <div className={`category gap-1 round-07 pd-1 ${id == 5 && "active_category"}`} onClick={() => changePage(5)}>
                            <div className="icon"><PoolIcon /></div>
                            <p className="text">{dataTranslate?.data?.data["Pools"]}</p>
                        </div>
                    </div>
                    {
                        id == undefined ? <BranchInformations />
                            : id == 2 ? <Sportsmens />
                                : id == 3 ? <Coaches />
                                    : id == 4 ? <LeaderShips />
                                        : <Pools />
                    }
                </div>
            </div>
            <Partners />
        </>
    )
}

export default Branches
