import React from 'react';
import Select from 'react-select';
import { useQuery } from 'react-query';
import Partners from '../home/Partners';
import '../../styles/additional/Competetions.scss';
import BackImg from '../../assets/images/diving.webp';
import BottomPagination from '../pagination/BottomPagination';
import { useParentContext } from '../../contexts/ParentContext';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { fetchSports, fetchTournaments, fetchTranslate, fetchYears } from '../../api/Api';

function Competetions() {

    // router options

    const path = useLocation();
    const [param, setParam] = useSearchParams();

    // API options

    const dataYears = useQuery('all-years', fetchYears);
    const dataSports = useQuery('all-sports', fetchSports);
    const dataTranslate = useQuery('translate', fetchTranslate);
    const dataCompetetions = useQuery(['competetions', path.search], () => fetchTournaments(path.search));

    // select (sports) options

    const sportOptions = dataSports?.data?.data?.length > 0 ? [{ value: "", label: "-------" }, ...dataSports?.data?.data?.map((item) => (
        { value: item?.id, label: item?.name }
    ))] : [];

    // select (type) options

    const typeOptions = [
        { value: '', label: '-------' },
        { value: 'National', label: `${dataTranslate?.data?.data["National"]}` },
        { value: 'International', label: `${dataTranslate?.data?.data["International"]}` },
    ];

    // select (year) options

    const yearOptions = dataYears?.data?.data?.years?.length > 0 ? [{ value: "", label: "-------" }, ...dataYears?.data?.data?.years?.map((item) => (
        { value: item?.date_start__year, label: item?.date_start__year }
    ))] : [];

    // select (month) options

    const monthOptions = [
        { value: '', label: '-------' },
        { value: '1', label: `${dataTranslate?.data?.data["month1"]}` },
        { value: '2', label: `${dataTranslate?.data?.data["month2"]}` },
        { value: '3', label: `${dataTranslate?.data?.data["month3"]}` },
        { value: '4', label: `${dataTranslate?.data?.data["month4"]}` },
        { value: '5', label: `${dataTranslate?.data?.data["month5"]}` },
        { value: '6', label: `${dataTranslate?.data?.data["month6"]}` },
        { value: '7', label: `${dataTranslate?.data?.data["month7"]}` },
        { value: '8', label: `${dataTranslate?.data?.data["month8"]}` },
        { value: '9', label: `${dataTranslate?.data?.data["month9"]}` },
        { value: '10', label: `${dataTranslate?.data?.data["month10"]}` },
        { value: '11', label: `${dataTranslate?.data?.data["month11"]}` },
        { value: '12', label: `${dataTranslate?.data?.data["month12"]}` },
    ];

    // useContext hook options

    const { changeTourPageNavigate, changeTourGender, changeMonth, changeYear, changeTourType, changeSportType, gender } = useParentContext();

    return (
        <>
            <div className="Competetions parent">
                <div className="c1">
                    <img src={BackImg} alt="back-img" className="back-img" />
                    <h1 className="title main_title">{dataTranslate?.data?.data["only"]} {dataTranslate?.data?.data["forward"]}</h1>
                </div>
                <div className="wrapper c2 gap-3">
                    <h1 className="title">{dataTranslate?.data?.data["Tournaments"]}</h1>
                    <div className="filter gap-2 mtop-1">
                        <Select
                            value={sportOptions?.filter((c) => c.value == param.get('sport'))}
                            options={sportOptions}
                            placeholder={`${dataTranslate?.data?.data["type_sport"]}`}
                            onChange={(e) => changeSportType(e)}
                            className='select text'
                        />
                        <Select
                            value={typeOptions?.filter((c) => c.value == param.get('type'))}
                            options={typeOptions}
                            placeholder={`${dataTranslate?.data?.data["Type:"]}`}
                            onChange={(e) => changeTourType(e)}
                            className='select text'
                        />
                        <Select
                            value={yearOptions?.filter((c) => c.value == param.get('date_year'))}
                            options={yearOptions}
                            placeholder={`${dataTranslate?.data?.data["type_year"]}`}
                            onChange={(e) => changeYear(e)}
                            className='select text'
                        />
                        <Select
                            value={monthOptions?.filter((c) => c.value == param.get('date_month'))}
                            options={monthOptions}
                            placeholder={`${dataTranslate?.data?.data["type_month"]}`}
                            onChange={(e) => changeMonth(e)}
                            className='select text'
                        />
                        <div className="type_gender gap-1">
                            <label htmlFor="gender" className='text'>{dataTranslate?.data?.data["Male"]}</label>
                            <input type="checkbox" id="gender" checked={gender == 'female'} onChange={() => changeTourGender()} />
                            <label htmlFor="gender" className='badge'></label>
                            <label htmlFor="gender" className='text'>{dataTranslate?.data?.data["Female"]}</label>
                        </div>
                    </div>
                    <div className="colors gap-3 mbot-1">
                        {dataSports?.data?.data?.map((item) => (
                            <p key={item?.id} className={`text ${item?.id == 1 ? "info" : item?.id == 2 ? "green" : item?.id == 3 ? "pink" : "dodger"}`}>{item?.name}</p>
                        ))}
                    </div>
                    <div className="cards gap-2">
                        {dataCompetetions?.data?.data?.results?.map((item) => (
                            <div key={item?.id} className={`info_card round-1 ${item?.sport?.id == 1 ? "info" : item?.sport?.id == 2 ? "green" : item?.sport?.id == 3 ? "pink" : "dodger"}`}>
                                <img src={item?.logo} alt="logo" className="logo" />
                                <div className="time pd-2 text">{item?.date_start} <br /> {item?.date_end}</div>
                                <p className="desc text">{item?.title}</p>
                                <div className='time pd-3'>
                                    <Link to={`${item?.id}`} className='link text' onClick={() => window.scrollTo(0, 0)}>{dataTranslate?.data?.data["See more"]}...</Link>
                                </div>
                            </div>
                        ))}
                    </div>
                    <BottomPagination dataLength={Math.ceil(dataCompetetions?.data?.data?.count / 10)} changeNavigate={changeTourPageNavigate} />
                </div>
            </div>
            <Partners />
        </>
    )
}

export default Competetions
