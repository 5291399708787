import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { fetchVideos } from '../../api/Api';
import '../../styles/additional/Videos.scss';
import { useLocation } from 'react-router-dom';
import BottomPagination from '../pagination/BottomPagination';
import { useParentContext } from '../../contexts/ParentContext';
import { CloseIcon, VideoBtnIcon } from '../../assets/svgImages';

function Videos() {

    // router options

    const path = useLocation();

    // video-modal options

    const [videoItem, setVideoItem] = useState(null);

    // API options

    const dataVideos = useQuery(['video-gallery', path.search], () => fetchVideos(path.search));

    // useContext hook options

    const { changeSimplePageNavigate } = useParentContext();

    return (
        <>
            <div className="Videos parent">
                <div className="wrapper gap-3">
                    {dataVideos?.data?.data?.results?.map((item) => (
                        <div key={item?.id} className="video" onClick={() => setVideoItem(item?.video)}>
                            <img src={item?.cover} alt="img" className="img round-2" />
                            <div className="icon">
                                <VideoBtnIcon />
                            </div>
                            <div className="texts round-2 pd-2 gap-1">
                                <div className="badge"></div>
                                <p className="text">{item?.title}</p>
                                {/* <div className="text" dangerouslySetInnerHTML={{ __html: item?.description }} /> */}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="wrapper c2">
                    <BottomPagination dataLength={Math.ceil(dataVideos?.data?.data?.count / 10)} changeNavigate={changeSimplePageNavigate} />
                </div>
            </div>
            {videoItem &&
                <div className="video-container">
                    <div className="close-icon" onClick={() => setVideoItem(null)}>
                        <CloseIcon />
                    </div>
                    <video src={videoItem} controls></video>
                </div>
            }
        </>
    )
}

export default Videos
