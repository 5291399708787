import React from 'react';
import { useQuery } from 'react-query';
import Partners from '../home/Partners';
import '../../styles/additional/Pools.scss';
import { Link, useLocation } from 'react-router-dom';
import BackImg from '../../assets/images/diving.webp';
import { fetchPools, fetchTranslate } from '../../api/Api';
import BottomPagination from '../pagination/BottomPagination';
import { useParentContext } from '../../contexts/ParentContext';

function Pools() {

    // router options

    const path = useLocation();

    // API options

    const dataTranslate = useQuery('translate', fetchTranslate);
    const dataPools = useQuery(['pools', path.search], () => fetchPools(path.search));

    // useContext hook options

    const { changeSimplePageNavigate } = useParentContext();

    return (
        <>
            <div className="Pools parent">
                <div className="c1 wrapper">
                    <img src={BackImg} alt="back-img" className="back-img" />
                </div>
                <div className="c2 wrapper gap-3">
                    <h1 className="sub-title">{dataTranslate?.data?.data["Pools"]}</h1>
                    <div className="cards gap-3">
                        {dataPools?.data?.data?.results?.map((item) => (
                            <Link key={item?.id} to={`${item?.id}`} className="branch gap-1-5 round-1 pd-2-5" onClick={() => window.scrollTo(0, 0)}>
                                <img src={item?.image} alt="img" className="img round-07" />
                                <div className="texts gap-05">
                                    <p className="text">{item?.name}</p>
                                    <p className="min-text mtop-05"><b>{dataTranslate?.data?.data["Phone Number"]}:</b>  {item?.branch?.phone}</p>
                                    <p className="min-text"><b>{dataTranslate?.data?.data["Address"]}:</b> {item?.branch?.region?.name}</p>
                                </div>
                            </Link>
                        ))}
                    </div>
                    <BottomPagination dataLength={Math.ceil(dataPools?.data?.data?.count / 10)} changeNavigate={changeSimplePageNavigate} />
                </div>
            </div>
            <Partners />
        </>
    )
}

export default Pools
