import { useQuery } from "react-query";
import { fetchRegions } from "../api/Api";
import { createContext, useContext, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const ParentContext = createContext({});

export function useParentContext() {
    return useContext(ParentContext)
};

export function ParentContextProvider({ children }) {

    // router options

    const path = useLocation();
    const navigate = useNavigate();
    const splitRoute = path.search.split('&');
    const [param, setParam] = useSearchParams();

    // API options

    const dataRegions = useQuery('regions', fetchRegions);

    // pagination options

    function changePageNavigate(item) {
        if (splitRoute.length > 1) {
            navigate(`?page=${item}&${splitRoute.slice(1, 2)}&${splitRoute.slice(2, 3)}&${splitRoute.slice(3)}`);
        } else {
            navigate(`?page=${item}&name=&gender=&region=`);
        }
    };

    // simple pagination options

    function changeSimplePageNavigate(item) {
        navigate(`?page=${item}`);
    };

    // search options

    function handleSearch(item) {
        if (splitRoute.length > 1) {
            navigate(`?page=1&name=${item}&${splitRoute.slice(2, 3)}&${splitRoute.slice(3)}`);
        } else {
            navigate(`?page=1&name=${item}&gender=&region=`);
        }
    };

    // gender options

    const [gender, setGender] = useState(splitRoute.length > 1 ? param.get('gender') : 'male');

    function changeGender() {
        setGender(gender == 'male' ? 'female' : 'male');
        if (splitRoute.length > 1) {
            if (gender == 'male') {
                navigate(`?page=1&${splitRoute.slice(1, 2)}&gender=female&${splitRoute.slice(3)}`);
            } else {
                navigate(`?page=1&${splitRoute.slice(1, 2)}&gender=male&${splitRoute.slice(3)}`);
            }
        } else {
            if (gender == 'male') {
                navigate(`?page=1&name=&gender=female&region=`);
            } else {
                navigate(`?page=1&name=&gender=male&region=`);
            }
        }
    };

    // region options

    const regionOptions = dataRegions?.data?.data?.length > 0 ? [{ value: "", label: "-------" }, ...dataRegions?.data?.data?.map((item) => (
        { value: item?.id, label: item?.name }
    ))] : [];

    function changeRegion(item) {
        if (splitRoute.length > 1) {
            navigate(`?page=1&${splitRoute.slice(1, 2)}&${splitRoute.slice(2, 3)}&region=${item}`);
        } else {
            navigate(`?page=1&name=&gender=&region=${item}`);
        }
    };


    //-------------------------------------------bular alohida page lar uchun (NationalTeam.jsx)-------------------------------------------//

    // pagination options for NationalTeam.jsx

    function changeTeamPageNavigate(item) {
        if (splitRoute.length > 1) {
            navigate(`?page=${item}&${splitRoute.slice(1, 2)}&${splitRoute.slice(2, 3)}&${splitRoute.slice(3, 4)}&${splitRoute.slice(4, 5)}`);
        } else {
            navigate(`?page=${item}&name=&gender=&region=&team__type=`);
        }
    };

    // gender options for NationalTeam.jsx

    function changeTeamGender() {
        setGender(gender == 'male' ? 'female' : 'male');
        if (splitRoute.length > 1) {
            if (gender == 'male') {
                navigate(`?page=1&${splitRoute.slice(1, 2)}&gender=female&${splitRoute.slice(3, 4)}&${splitRoute.slice(4, 5)}`);
            } else {
                navigate(`?page=1&${splitRoute.slice(1, 2)}&gender=male&${splitRoute.slice(3, 4)}&${splitRoute.slice(4, 5)}`);
            }
        } else {
            if (gender == 'male') {
                navigate(`?page=1&name=&gender=female&region=&team__type=`);
            } else {
                navigate(`?page=1&name=&gender=male&region=&team__type=`);
            }
        }
    };

    // type of national team options for NationalTeam.jsx

    function changeTeamType(item) {
        if (splitRoute.length > 1) {
            navigate(`?page=1&${splitRoute.slice(1, 2)}&${splitRoute.slice(2, 3)}&${splitRoute.slice(3, 4)}&team__type=${item}`);
        } else {
            navigate(`?page=1&name=&gender=&region=&team__type=${item}`);
        }
    };

    //-------------------------------------------bular alohida page lar uchun (NationalTeam.jsx)-------------------------------------------//

    //-------------------------------------------***********************************************-------------------------------------------//

    //-------------------------------------------bular alohida page lar uchun (Competetions.jsx)-------------------------------------------//

    // pagination options for Competetions.jsx

    function changeTourPageNavigate(item) {
        if (splitRoute.length > 1) {
            navigate(`?page=${item}&${splitRoute.slice(1, 2)}&${splitRoute.slice(2, 3)}&${splitRoute.slice(3, 4)}&${splitRoute.slice(4, 5)}&${splitRoute.slice(5, 6)}`);
        } else {
            navigate(`?page=${item}&sport=&type=&gender=&date_year=&date_month=`);
        }
    };

    // gender options for Competetions.jsx

    function changeTourGender() {
        setGender(gender == 'male' ? 'female' : 'male');
        if (splitRoute.length > 1) {
            if (gender == 'male') {
                navigate(`?page=1&${splitRoute.slice(1, 2)}&${splitRoute.slice(2, 3)}&gender=female&${splitRoute.slice(4, 5)}&${splitRoute.slice(5, 6)}`);
            } else {
                navigate(`?page=1&${splitRoute.slice(1, 2)}&${splitRoute.slice(2, 3)}&gender=male&${splitRoute.slice(4, 5)}&${splitRoute.slice(5, 6)}`);
            }
        } else {
            if (gender == 'male') {
                navigate(`?page=1&sport=&type=&gender=female&date_year=&date_month=`);
            } else {
                navigate(`?page=1&sport=&type=&gender=male&date_year=&date_month=`);
            }
        }
    };

    // month options for Competetions.jsx

    function changeMonth(e) {
        if (splitRoute.length > 1) {
            navigate(`?page=1&${splitRoute.slice(1, 2)}&${splitRoute.slice(2, 3)}&${splitRoute.slice(3, 4)}&${splitRoute.slice(4, 5)}&date_month=${e.value}`);
        } else {
            navigate(`?page=1&sport=&type=&gender=&date_year=&date_month=${e.value}`);
        }
    };

    // year options for Competetions.jsx

    function changeYear(e) {
        if (splitRoute.length > 1) {
            navigate(`?page=1&${splitRoute.slice(1, 2)}&${splitRoute.slice(2, 3)}&${splitRoute.slice(3, 4)}&date_year=${e.value}&${splitRoute.slice(5, 6)}`);
        } else {
            navigate(`?page=1&sport=&type=&gender=&date_year=${e.value}&date_month=`);
        }
    };

    // type of tournaments options for Competetions.jsx

    function changeTourType(e) {
        if (splitRoute.length > 1) {
            navigate(`?page=1&${splitRoute.slice(1, 2)}&type=${e.value}&${splitRoute.slice(3, 4)}&${splitRoute.slice(4, 5)}&${splitRoute.slice(5, 6)}`);
        } else {
            navigate(`?page=1&sport=&type=${e.value}&gender=&date_year=&date_month=`);
        }
    };

    // type of sport options for Competetions.jsx

    function changeSportType(e) {
        if (splitRoute.length > 1) {
            navigate(`?page=1&sport=${e.value}&${splitRoute.slice(2, 3)}&${splitRoute.slice(3, 4)}&${splitRoute.slice(4, 5)}&${splitRoute.slice(5, 6)}`);
        } else {
            navigate(`?page=1&sport=${e.value}&type=&gender=&date_year=&date_month=`);
        }
    };

    //-------------------------------------------bular alohida page lar uchun (Competetions.jsx)-------------------------------------------//

    return (
        <ParentContext.Provider value={{ changePageNavigate, changeSimplePageNavigate, handleSearch, changeGender, changeRegion, changeTourPageNavigate, changeTourGender, changeMonth, changeYear, changeTourType, changeSportType, changeTeamPageNavigate, changeTeamGender, changeTeamType, gender, regionOptions }}>
            {children}
        </ParentContext.Provider>
    )
};