import React from 'react';
import { useQuery } from 'react-query';
import Rules from './sportstype/Rules';
import Partners from '../home/Partners';
import Judges from './sportstype/Judges';
import Coaches from './sportstype/Coaches';
import '../../styles/additional/Diving.scss';
import Sportsmens from './sportstype/Sportsmens';
import Tournaments from './sportstype/Tournaments';
import NationalTeam from './sportstype/NationalTeam';
import BackImg from '../../assets/images/diving.webp';
import { fetchSports, fetchTranslate } from '../../api/Api';
import { useLocation, useNavigate } from 'react-router-dom';
import { SportsTypeProvider } from '../../contexts/SportsTypeContext';
import { AthletesIcon, CoachIcon, CompetitionIcon, RefereeIcon, RulesIcon, SbornieIcon } from '../../assets/svgImages';

function SportsType() {

    // router options

    const path = useLocation();
    const navigate = useNavigate();
    const splitRoute = path.search.split('&');

    let routePath = path.pathname.split('-')[path.pathname.split('-').length - 1];
    let routeParent = path.pathname.split(routePath)[0];

    // API options

    const dataSports = useQuery('all-sports', fetchSports);
    const dataTranslate = useQuery('translate', fetchTranslate);

    // navigate options

    function changeNavigate(item) {
        navigate(`${item}`);
    };

    function changePage(item) {
        navigate(`${routeParent}${item}${splitRoute.slice(0, 1)}`);
    };

    return (
        <SportsTypeProvider>
            <div className="Diving parent">
                <div className="wrapper c1">
                    <img src={BackImg} alt="back-img" className="back-img" />
                    <h1 className="title main_title">{dataTranslate?.data?.data["fly_text"]}</h1>
                </div>
                <div className="wrapper c2 gap-3">
                    <div className="selection">
                        <select className='title pd-1' value={`${path.pathname}${splitRoute.slice(0, 1)}`} onChange={(e) => changeNavigate(e.target.value)}>
                            {dataSports?.data?.data?.map((item) => (
                                <option key={item?.id} value={`/${item?.slug}-${routePath}?sport=${item?.id}`}>{item?.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="categories gap-1">
                        <div className={`category gap-1 round-07 pd-1 ${routePath == 'sorevnovaniia' && "active_category"}`} onClick={() => changePage('sorevnovaniia')}>
                            <div className="icon"><CompetitionIcon /></div>
                            <p className="text">{dataTranslate?.data?.data["Tournaments"]}</p>
                        </div>
                        <div className={`category gap-1 round-07 pd-1 ${routePath == 'sportsmeny' && "active_category"}`} onClick={() => changePage('sportsmeny')}>
                            <div className="icon"><AthletesIcon /></div>
                            <p className="text">{dataTranslate?.data?.data["Athletes"]}</p>
                        </div>
                        <div className={`category gap-1 round-07 pd-1 ${routePath == 'trenery' && "active_category"}`} onClick={() => changePage('trenery')}>
                            <div className="icon"><CoachIcon /></div>
                            <p className="text">{dataTranslate?.data?.data["Coaches"]}</p>
                        </div>
                        <div className={`category gap-1 round-07 pd-1 ${routePath == 'sudi' && "active_category"}`} onClick={() => changePage('sudi')}>
                            <div className="icon"><RefereeIcon /></div>
                            <p className="text">{dataTranslate?.data?.data["Referees"]}</p>
                        </div>
                        <div className={`category gap-1 round-07 pd-1 ${routePath == 'pravila' && "active_category"}`} onClick={() => changePage('pravila')}>
                            <div className="icon"><RulesIcon /></div>
                            <p className="text">{dataTranslate?.data?.data["Rules"]}</p>
                        </div>
                        <div className={`category gap-1 round-07 pd-1 ${routePath == 'sbornye' && "active_category"}`} onClick={() => changePage('sbornye')}>
                            <div className="icon"><SbornieIcon /></div>
                            <p className="text">{dataTranslate?.data?.data["Teams"]}</p>
                        </div>
                    </div>
                    {
                        routePath == 'sorevnovaniia' ? <Tournaments />
                            : routePath == 'sportsmeny' ? <Sportsmens />
                                : routePath == 'trenery' ? <Coaches />
                                    : routePath == 'sudi' ? <Judges />
                                        : routePath == 'pravila' ? <Rules />
                                            : <NationalTeam />
                    }
                </div>
            </div>
            <Partners />
        </SportsTypeProvider>
    )
}

export default SportsType
