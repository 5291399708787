import React from 'react';
import { useQuery } from 'react-query';
import '../../styles/home/OurLife.scss';
import GlassImg from '../../assets/images/glass.jpg';
import { Link, useLocation } from 'react-router-dom';
import { fetchGalleries, fetchSocialLinks, fetchTranslate } from '../../api/Api';
import { EyeIcon, FacebookIcon, InstagramIcon, TelegramIcon } from '../../assets/svgImages';

function OurLife() {

    // router options

    const path = useLocation();

    // API options

    const dataTranslate = useQuery('translate', fetchTranslate);
    const dataSocialLinks = useQuery('social-links', fetchSocialLinks);
    const dataGalleries = useQuery(['photo-galleries', '?top=true'], () => fetchGalleries('?top=true'));

    return (
        <>
            <div className="OurLife parent">
                <img src={GlassImg} alt="img" className="glass_img" />
                <div className="wrapper gap-1-5">
                    {dataGalleries?.data?.data?.results?.map((item) => (
                        item?.top &&
                        <Link key={item?.id} to={`/media-fotogalereia/${item?.id}`} className="img_cont" onClick={() => window.scrollTo(0, 0)}>
                            <img src={item?.images[0]?.image} alt="img" className="img" />
                            <div className="eye_icon"><EyeIcon /></div>
                        </Link>
                    ))}
                    <div className="img_cont">
                        <div className="socials gap-2">
                            <p className="text">{dataTranslate?.data?.data["our_life"]}</p>
                            <div className="icons gap-2">
                                <a href={dataSocialLinks?.data?.data?.facebook_link} target='_blank' className="icon"><FacebookIcon /></a>
                                <a href={dataSocialLinks?.data?.data?.instagram_link} target='_blank' className="icon"><InstagramIcon /></a>
                                <a href={dataSocialLinks?.data?.data?.telegram_link} target='_blank' className="icon" title='@aquatics_uz'><TelegramIcon /></a>
                            </div>
                            <Link to="/media" className="media_btn min-text" onClick={() => window.scrollTo(0, 0)}>{dataTranslate?.data?.data["media"]}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OurLife
