import React from 'react';
import { useQuery } from 'react-query';
import Partners from '../home/Partners';
import FileImg from '../../assets/images/pdf.png';
import BackImg from '../../assets/images/diving.webp';
import { DownloadIcon } from '../../assets/svgImages';
import '../../styles/additional/CompetetionItem.scss';
import { fetchTournamentItem, fetchTranslate } from '../../api/Api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

function CompetetionItem() {

    // router options

    const { id } = useParams();
    const path = useLocation();
    const navigate = useNavigate();

    // API options

    const dataTranslate = useQuery('translate', fetchTranslate);
    const dataCompetetions = useQuery(['competetions', id], () => fetchTournamentItem(id));

    return (
        <>
            <div className="CompetetionItem parent">
                <div className="c1 wrapper">
                    <img src={BackImg} alt="back-img" className="back-img" />
                    <h1 className="main_title title">{dataTranslate?.data?.data["fly_text"]}</h1>
                </div>
                {path.search == "?result" ?
                    <div className="c2 wrapper gap-2">
                        <h1 className="sub-title">{dataTranslate?.data?.data["Tournament results"]}</h1>
                        {dataCompetetions?.data?.data?.tournament_result?.map((item) => (
                            <div key={item?.id} className="info">
                                <div className='text' dangerouslySetInnerHTML={{ __html: item?.result }} />
                            </div>
                        ))}
                    </div>
                    :
                    <div className="c2 wrapper gap-3">
                        <div className="info_card round-2 pd-2 gap-1">
                            <img src={dataCompetetions?.data?.data?.logo} alt="logo" className="logo" />
                            <p className="big-text an-text mbot-1">{dataCompetetions?.data?.data?.title}</p>
                            <div className="tool gap-1-5">
                                <p className="text left">{dataTranslate?.data?.data["place_of"]}:</p>
                                <p className="text right">{dataCompetetions?.data?.data?.place}</p>
                            </div>
                            <div className="tool gap-1-5">
                                <p className="text left">{dataTranslate?.data?.data["category"]}:</p>
                                <p className="text right">{dataCompetetions?.data?.data?.type == 'National' ? `${dataTranslate?.data?.data["National"]}` : `${dataTranslate?.data?.data["International"]}`}</p>
                            </div>
                            <div className="tool gap-1-5">
                                <p className="text left">{dataTranslate?.data?.data["srok"]}:</p>
                                <p className="text right">{dataCompetetions?.data?.data?.date_start} - {dataCompetetions?.data?.data?.date_end}</p>
                            </div>
                            <div className="tool gap-1-5">
                                <p className="text left">{dataTranslate?.data?.data["Tournament results"]}:</p>
                                <div className="text right link" onClick={() => navigate(`?result`)}>{dataTranslate?.data?.data["see_result"]}</div>
                            </div>
                        </div>
                        <h1 className="sub-title">{dataTranslate?.data?.data["more_info"]}</h1>
                        <p className="text desc">{dataCompetetions?.data?.data?.description}</p>
                        <h1 className="sub-title">{dataTranslate?.data?.data["last_protocol"]}</h1>
                        <div className="page_5 gap-2">
                            {dataCompetetions?.data?.data?.tournament_protocol?.map((item) => (
                                <div key={item?.id} className="file_card round-1">
                                    <div className="img">
                                        <img src={FileImg} alt="file" />
                                    </div>
                                    <div className="desc pd-2 text">{item?.name}</div>
                                    <div className="download pd-2 gap-1">
                                        <div className="icon"><DownloadIcon /></div>
                                        <a href={item?.document} download={"file"} target='_blank' className='link text'>{dataTranslate?.data?.data["download"]}</a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                }
            </div>
            <Partners />
        </>
    )
}

export default CompetetionItem
