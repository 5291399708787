import React from 'react';
import "swiper/css";
import '../../styles/home/Header.scss';
import { useQuery } from 'react-query';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import HeadImg from '../../assets/images/head.jpg';
import { Link, useLocation } from 'react-router-dom';
import { fetchNews, fetchTranslate } from '../../api/Api';

function Header() {

    // router options

    const path = useLocation();

    // API options

    const dataTranslate = useQuery('translate', fetchTranslate);
    const dataNews = useQuery(['news', path.search], () => fetchNews(path.search));

    return (
        <>
            <div className="Header parent">
                <img src={HeadImg} alt="img" className="head_img" />
                <div className="wrapper">
                    <h1 className="title head_title">{dataTranslate?.data?.data["only"]}</h1>
                    <h1 className="head_sub_title title">{dataTranslate?.data?.data["forward"]}</h1>
                    <div className="news">
                        <h1 className="sub-title gap-2">{dataTranslate?.data?.data["News"]} <span></span></h1>
                        <Swiper
                            spaceBetween={40}
                            slidesPerView={1}
                            loop={true}
                            autoplay={{
                                delay: 4000,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay]}
                            className="mySwiper"
                            breakpoints={{
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                            }}
                        >
                            {dataNews?.data?.data?.results?.map((item) => (
                                <SwiperSlide key={item?.id}>
                                    <Link to={`/media-novosti/${item?.id}`} className="new_card pd-1-5 gap-05 round-1-5" onClick={() => window.scrollTo(0, 0)}>
                                        <p className="text date">{item.pub_date.slice(0, 10)}</p>
                                        <p className="big-text name">{item?.title}</p>
                                        <div className="text desc" dangerouslySetInnerHTML={{ __html: item?.description }} />
                                        <p className='text explore'>{dataTranslate?.data?.data["See more"]}...</p>
                                    </Link>
                                </SwiperSlide>
                            ))}
                            {dataNews?.data?.data?.results?.map((item) => (
                                <SwiperSlide key={item?.id}>
                                    <Link to={`/media-novosti/${item?.id}`} className="new_card pd-1-5 gap-05 round-1-5" onClick={() => window.scrollTo(0, 0)}>
                                        <p className="text date">{item.pub_date.slice(0, 10)}</p>
                                        <p className="big-text name">{item?.title}</p>
                                        <div className="text desc" dangerouslySetInnerHTML={{ __html: item?.description }} />
                                        <p className='text explore'>{dataTranslate?.data?.data["See more"]}...</p>
                                    </Link>
                                </SwiperSlide>
                            ))}
                            {dataNews?.data?.data?.results?.map((item) => (
                                <SwiperSlide key={item?.id}>
                                    <Link to={`/media-novosti/${item?.id}`} className="new_card pd-1-5 gap-05 round-1-5" onClick={() => window.scrollTo(0, 0)}>
                                        <p className="text date">{item.pub_date.slice(0, 10)}</p>
                                        <p className="big-text name">{item?.title}</p>
                                        <div className="text desc" dangerouslySetInnerHTML={{ __html: item?.description }} />
                                        <p className='text explore'>{dataTranslate?.data?.data["See more"]}...</p>
                                    </Link>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header
