import React from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import FileImg from '../../../assets/images/pdf.png';
import { DownloadIcon } from '../../../assets/svgImages';
import '../../../styles/additional/sportstype/Rules.scss';
import { fetchRules, fetchTranslate } from '../../../api/Api';

function Rules() {

    // router options

    const path = useLocation();

    // API options

    const dataTranslate = useQuery('translate', fetchTranslate);
    const dataRules = useQuery(['rules', path.search], () => fetchRules(path.search));

    return (
        <div className="Rules gap-2">
            {dataRules?.data?.data?.map((item) => (
                <div key={item?.id} className="file_card round-1">
                    <div className="img">
                        <img src={FileImg} alt="file" />
                    </div>
                    <div className="desc pd-2 text">{item?.description}</div>
                    <div className="download pd-2 gap-1">
                        <div className="icon"><DownloadIcon /></div>
                        <a href={item?.file} download={"file.webp"} className='link text'>{dataTranslate?.data?.data["download"]}</a>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default Rules
