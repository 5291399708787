import React from 'react';
import axios from 'axios';
import { useQuery } from 'react-query';
import Partners from '../home/Partners';
import { useParams } from 'react-router-dom';
import { fetchTranslate } from '../../api/Api';
import { ATHLETES_URL } from '../../api/ApiUrl';
import Gold from '../../assets/images/gold.png';
import '../../styles/additional/SportSmenItem.scss';
import Silver from '../../assets/images/silver.png';
import Bronze from '../../assets/images/silver.png';
import BackImg from '../../assets/images/diving.webp';

function SportSmenItem() {

    // router options

    const { id } = useParams();

    const fetchAthlete = () => {
        return axios.get(`${ATHLETES_URL}/${id}`)
    };

    // API options

    const { data } = useQuery('athlete', fetchAthlete);
    const dataTranslate = useQuery('translate', fetchTranslate);

    return (
        <>
            <div className="SportSmenItem parent">
                <div className="c1 wrapper">
                    <img src={BackImg} alt="back-img" className="back-img" />
                </div>
                <div className="c2 wrapper gap-3">
                    <h1 className="title">{dataTranslate?.data?.data["Athletes"]}</h1>
                    <div className="infos gap-3">
                        <img src={data?.data?.image} alt="img" className="img round-1" />
                        <div className="texts gap-05">
                            <p className="big-text mbot-1">{data?.data?.name}</p>
                            <p className="text"><b>{dataTranslate?.data?.data["direction"]}:</b> {data?.data?.sport?.name}</p>
                            <p className="text"><b>{dataTranslate?.data?.data["Birth date"]}:</b> {data?.data?.birth_date}</p>
                            <p className="text"><b>{dataTranslate?.data?.data["Coach"]}:</b> {data?.data?.coach?.map((c) => (`${c?.name}, `))}</p>
                            <p className="text"><b>{dataTranslate?.data?.data["Region"]}:</b> {data?.data?.region?.name}</p>
                            <p className="big-text mtop-1-5">{dataTranslate?.data?.data["awards"]}</p>
                            <div className="medals gap-1-5 mtop-1">
                                {data?.data?.best_results?.map((item) => (
                                    <div key={item?.id} className="medal gap-05">
                                        <img src={item?.medal == 'gold' ? Gold : item?.medal == "silver" ? Silver : Bronze} alt="img" className="medal_img" />
                                        <p className="text">{item?.date?.split('-').slice(0, 1)}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <p className="big-text mtop-2">{dataTranslate?.data?.data["Best results"]}</p>
                    <div className="athlete_table">
                        <table>
                            <thead>
                                <tr className='text'>
                                    <th className='pd-05'>{dataTranslate?.data?.data["event"]}</th>
                                    <th className='pd-05'>{dataTranslate?.data?.data["Time"]}</th>
                                    <th className='pd-05'>{dataTranslate?.data?.data["Date"]}</th>
                                    <th className='pd-05'>{dataTranslate?.data?.data["Age"]}</th>
                                    <th className='pd-05'>{dataTranslate?.data?.data["Country"]}</th>
                                    <th className='pd-05'>{dataTranslate?.data?.data["Medal"]}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data?.best_results?.map((item) => (
                                    <tr key={item?.id} className='text'>
                                        <td className='pd-1'>{item?.tournament_name}</td>
                                        <td className='pd-1'> {item?.time}</td>
                                        <td className='pd-1'>{item?.date}</td>
                                        <td className='pd-1'>{item?.age}</td>
                                        <td className='pd-1'>{item?.country}</td>
                                        <td className='pd-1'>{item?.medal == 'gold' ? `${dataTranslate?.data?.data["Gold"]}` : item?.medal == "silver" ? `${dataTranslate?.data?.data["Silver"]}` : `${dataTranslate?.data?.data["Bronze"]}`}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Partners />
        </>
    )
}

export default SportSmenItem
