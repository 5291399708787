import React from 'react';
import { useQuery } from 'react-query';
import Partners from '../home/Partners';
import { Link, useParams } from 'react-router-dom';
import '../../styles/additional/InterviewItem.scss';
import BackImg from '../../assets/images/diving.webp';
import { fetchInterviewItem, fetchInterviews, fetchTranslate } from '../../api/Api';

function InterviewItem() {

    // router options

    const { id } = useParams();

    // API options

    const dataTranslate = useQuery('translate', fetchTranslate);
    const dataInterviews = useQuery('interviews', fetchInterviews);
    const dataInterview = useQuery(['interview-item', id], () => fetchInterviewItem(id));

    return (
        <>
            <div className="InterviewItem parent">
                <div className="c1 wrapper">
                    <img src={BackImg} alt="back-img" className="back-img" />
                </div>
                <div className="c2 wrapper gap-3">
                    <h1 className="title">{dataTranslate?.data?.data["Interview"]}</h1>
                    <div className="infos">
                        <div className="left gap-1">
                            <img src={dataInterview?.data?.data?.cover} alt="img" className="img round-2-5" />
                            <p className="date big-text mtop-1">{dataInterview?.data?.data?.date}</p>
                            <p className="link text">{dataInterview?.data?.data?.title}</p>
                            <p className="desc min-text">{dataInterview?.data?.data?.description}</p>
                        </div>
                        <div className="right gap-3">
                            {dataInterviews?.data?.data?.results?.slice(0, 5)?.map((item) => (
                                <div key={item?.id} className="info gap-05">
                                    <img src={item?.cover} alt="img" className="img round-1" />
                                    <p className="date min-text mtop-05">{item?.date}</p>
                                    <Link to={`/media-interviu/${item?.id}`} className="link min-text" onClick={() => window.scrollTo(0, 0)}>{item?.title}</Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Partners />
        </>
    )
}

export default InterviewItem
