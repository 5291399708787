import React from 'react';
import '../../styles/loaders/SpinnerLoader.scss';

function SpinnerLoader() {
    return (
        <div className="SpinnerLoader pd-2">
            <div className="loader"></div>
        </div>
    )
}

export default SpinnerLoader
