import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import '../../styles/home/HomeAbout.scss';
import Img from '../../assets/images/about.jpg';
import { fetchAbout, fetchTranslate } from '../../api/Api';

function HomeAbout() {

    // API options

    const dataAbout = useQuery('about', fetchAbout);
    const dataTranslate = useQuery('translate', fetchTranslate);

    return (
        <div className="HomeAbout parent">
            <div className="wrapper">
                <div className="left title">{dataTranslate?.data?.data["sila"]}</div>
                <div className="right gap-2">
                    <h1 className="sub-title">{dataTranslate?.data?.data["About Federation"]}</h1>
                    <p className="text desc">{dataAbout?.data?.data?.text}</p>
                    <Link to="/o-federatsii" className='explore big-text scale-05' onClick={() => window.scrollTo(0, 0)}>{dataTranslate?.data?.data["See more"]}</Link>
                </div>
            </div>
            <img src={Img} alt="img" className="full_img" />
        </div>
    )
}

export default HomeAbout
