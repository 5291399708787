import { useQuery } from "react-query";
import { fetchRegions } from "../api/Api";
import { createContext, useContext, useState } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const SportsTypeContext = createContext({});

export function useSportsTypeContext() {
    return useContext(SportsTypeContext)
};

export function SportsTypeProvider({ children }) {

    // router options

    const path = useLocation();
    const navigate = useNavigate();
    const splitRoute = path.search.split('&');
    const [param, setParam] = useSearchParams();

    // API options

    const dataRegions = useQuery('regions', fetchRegions);

    // pagination options

    function changePageNavigate(item) {
        if (splitRoute.length > 1) {
            navigate(`${splitRoute.slice(0, 1)}&page=${item}&${splitRoute.slice(2, 3)}&${splitRoute.slice(3, 4)}&${splitRoute.slice(4, 5)}`);
        } else {
            navigate(`${splitRoute.slice(0, 1)}&page=${item}&name=&gender=&region=`);
        }
    };

    // search options

    function handleSearch(item) {
        if (splitRoute.length > 1) {
            navigate(`${splitRoute.slice(0, 1)}&page=1&name=${item}&${splitRoute.slice(3, 4)}&${splitRoute.slice(4, 5)}`);
        } else {
            navigate(`${splitRoute.slice(0, 1)}&page=1&name=${item}&gender=&region=`);
        }
    };

    // gender options

    const [gender, setGender] = useState(splitRoute.length > 1 ? param.get('gender') : 'male');

    function changeGender() {
        setGender(gender == 'male' ? 'female' : 'male');
        if (splitRoute.length > 1) {
            if (gender == 'male') {
                navigate(`${splitRoute.slice(0, 1)}&page=1&${splitRoute.slice(2, 3)}&gender=female&${splitRoute.slice(4)}`);
            } else {
                navigate(`${splitRoute.slice(0, 1)}&page=1&${splitRoute.slice(2, 3)}&gender=male&${splitRoute.slice(4)}`);
            }
        } else {
            if (gender == 'male') {
                navigate(`${splitRoute.slice(0, 1)}&page=1&name=&gender=female&region=`);
            } else {
                navigate(`${splitRoute.slice(0, 1)}&page=1&name=&gender=male&region=`);
            }
        }
    };

    // region options

    function changeRegion(e) {
        if (splitRoute.length > 1) {
            navigate(`${splitRoute.slice(0, 1)}&page=1&${splitRoute.slice(2, 3)}&${splitRoute.slice(3, 4)}&region=${e.value}`);
        } else {
            navigate(`${splitRoute.slice(0, 1)}&page=1&name=&gender=&region=${e.value}`);
        }
    };

    // select (region) options

    const regionOptions = dataRegions?.data?.data?.length > 0 ? [{ value: "", label: "-------" }, ...dataRegions?.data?.data?.map((item) => (
        { value: item?.id, label: item?.name }
    ))] : [];


    //-------------------------------------------bular alohida page lar uchun (NationalTeam.jsx)-------------------------------------------//

    // pagination options for NationalTeam.jsx

    function changeTeamPageNavigate(item) {
        if (splitRoute.length > 1) {
            navigate(`${splitRoute.slice(0, 1)}&page=${item}&${splitRoute.slice(2, 3)}&${splitRoute.slice(3, 4)}&${splitRoute.slice(4, 5)}&${splitRoute.slice(5, 6)}`);
        } else {
            navigate(`${splitRoute.slice(0, 1)}&page=${item}&name=&gender=&region=&team__type=`);
        }
    };

    // gender options for NationalTeam.jsx

    function changeTeamGender() {
        setGender(gender == 'male' ? 'female' : 'male');

        if (splitRoute.length > 1) {
            if (gender == 'male') {
                navigate(`${splitRoute.slice(0, 1)}&page=1&${splitRoute.slice(2, 3)}&gender=female&${splitRoute.slice(4, 5)}&${splitRoute.slice(5, 6)}`);
            } else {
                navigate(`${splitRoute.slice(0, 1)}&page=1&${splitRoute.slice(2, 3)}&gender=male&${splitRoute.slice(4, 5)}&${splitRoute.slice(5, 6)}`);
            }
        } else {
            if (gender == 'male') {
                navigate(`${splitRoute.slice(0, 1)}&page=1&name=&gender=female&region=&team__type=`);
            } else {
                navigate(`${splitRoute.slice(0, 1)}&page=1&name=&gender=male&region=&team__type=`);
            }
        }
    };

    // type of national team options for NationalTeam.jsx

    function changeTeamType(item) {
        if (splitRoute.length > 1) {
            navigate(`${splitRoute.slice(0, 1)}&page=1&${splitRoute.slice(2, 3)}&${splitRoute.slice(3, 4)}&${splitRoute.slice(4, 5)}&team__type=${item}`);
        } else {
            navigate(`${splitRoute.slice(0, 1)}&page=1&name=&gender=&region=&team__type=${item}`);
        }
    };

    //-------------------------------------------bular alohida page lar uchun (NationalTeam.jsx)-------------------------------------------//

    //-------------------------------------------***********************************************-------------------------------------------//

    //-------------------------------------------bular alohida page lar uchun (Tournaments.jsx)-------------------------------------------//

    // pagination options for Tournaments.jsx

    function changeTourPageNavigate(item) {
        if (splitRoute.length > 1) {
            navigate(`${splitRoute.slice(0, 1)}&page=${item}&${splitRoute.slice(2, 3)}&${splitRoute.slice(3, 4)}&${splitRoute.slice(4, 5)}&${splitRoute.slice(5, 6)}`);
        } else {
            navigate(`${splitRoute.slice(0, 1)}&page=${item}&type=&gender=&date_year=&date_month=`);
        }
    };

    // gender options for Tournaments.jsx

    function changeTourGender() {
        setGender(gender == 'male' ? 'female' : 'male');
        if (splitRoute.length > 1) {
            if (gender == 'male') {
                navigate(`${splitRoute.slice(0, 1)}&page=1&${splitRoute.slice(2, 3)}&${splitRoute.slice(3, 4)}&gender=female&${splitRoute.slice(5, 6)}`);
            } else {
                navigate(`${splitRoute.slice(0, 1)}&page=1&${splitRoute.slice(2, 3)}&${splitRoute.slice(3, 4)}&gender=male&${splitRoute.slice(5, 6)}`);
            }
        } else {
            if (gender == 'male') {
                navigate(`${splitRoute.slice(0, 1)}&page=1&type=&gender=female&date_year=&date_month=`);
            } else {
                navigate(`${splitRoute.slice(0, 1)}&page=1&type=&gender=male&date_year=&date_month=`);
            }
        }
    };

    // month options for Tournaments.jsx

    function changeMonth(e) {
        if (splitRoute.length > 1) {
            navigate(`${splitRoute.slice(0, 1)}&page=1&${splitRoute.slice(2, 3)}&${splitRoute.slice(3, 4)}&${splitRoute.slice(4, 5)}&date_month=${e.value}`);
        } else {
            navigate(`${splitRoute.slice(0, 1)}&page=1&type=&gender=&date_year=&date_month=${e.value}`);
        }
    };

    // year options for Tournaments.jsx

    function changeYear(e) {
        if (splitRoute.length > 1) {
            navigate(`${splitRoute.slice(0, 1)}&page=1&${splitRoute.slice(2, 3)}&${splitRoute.slice(3, 4)}&date_year=${e.value}&${splitRoute.slice(5, 6)}`);
        } else {
            navigate(`${splitRoute.slice(0, 1)}&page=1&type=&gender=&date_year=${e.value}&date_month=`);
        }
    };

    // type of tournaments options for Tournaments.jsx

    function changeTourType(e) {
        if (splitRoute.length > 1) {
            navigate(`${splitRoute.slice(0, 1)}&page=1&${splitRoute.slice(2, 3)}&type=${e.value}&${splitRoute.slice(4, 5)}&${splitRoute.slice(5, 6)}`);
        } else {
            navigate(`${splitRoute.slice(0, 1)}&page=1&type=${e.value}&gender=&date_year=&date_month=`);
        }
    };

    //-------------------------------------------bular alohida page lar uchun (Tournaments.jsx)-------------------------------------------//

    return (
        <SportsTypeContext.Provider value={{ changePageNavigate, handleSearch, changeRegion, changeGender, changeTourPageNavigate, changeTourGender, changeMonth, changeYear, changeTourType, changeTeamPageNavigate, changeTeamGender, changeTeamType, gender, regionOptions }}>
            {children}
        </SportsTypeContext.Provider>
    )
};