import React from 'react';
import { useQuery } from 'react-query';
import Partners from '../home/Partners';
import { useParams } from 'react-router-dom';
import { fetchGalleriesItem } from '../../api/Api';
import '../../styles/additional/GalleriesItem.scss';
import BackImg from '../../assets/images/diving.webp';

function GalleriesItem() {

    // router options

    const { id } = useParams();

    // API options

    const dataGalleriesItem = useQuery(['galleries-item', id], () => fetchGalleriesItem(id));

    return (
        <>
            <div className="GalleriesItem parent">
                <div className="c1">
                    <img src={BackImg} alt="back-img" className="back-img" />
                </div>
                <div className="wrapper c2 gap-3">
                    <h1 className="title">{dataGalleriesItem?.data?.data?.title}</h1>
                    <div className="images gap-2">
                        {dataGalleriesItem?.data?.data?.images?.map((item) => (
                            <img key={item?.id} src={item?.image} alt="img" className="img" />
                        ))}
                    </div>
                    <div className='big-text' dangerouslySetInnerHTML={{ __html: dataGalleriesItem?.data?.data?.description }} />
                </div>
            </div>
            <Partners />
        </>
    )
}

export default GalleriesItem
