import React, { useState } from 'react';
import { useQuery } from 'react-query';
import Partners from '../home/Partners';
import { useLocation } from 'react-router-dom';
import '../../styles/additional/Leaderships.scss';
import BackImg from '../../assets/images/diving.webp';
import BottomPagination from '../pagination/BottomPagination';
import { useParentContext } from '../../contexts/ParentContext';
import { fetchDepartments, fetchStaff, fetchTranslate } from '../../api/Api';

function Employees() {

    // router options

    const path = useLocation();

    // API options

    const dataTranslate = useQuery('translate', fetchTranslate);
    const dataDepartments = useQuery('departments', fetchDepartments);
    const dataStaffs = useQuery(['staffs-o', path.search], () => fetchStaff(path.search));

    // select options

    const [select, setSelect] = useState("");

    // useContext hook options

    const { changeSimplePageNavigate } = useParentContext();

    return (
        <>
            <div className="Leaderships parent">
                <div className="c1 wrapper">
                    <img src={BackImg} alt="back-img" className="back-img" />
                </div>
                <div className="c2 wrapper gap-3">
                    <h1 className="title" style={{ textAlign: "center" }}>{dataTranslate?.data?.data["Staff"]}</h1>
                    <div className="selection">
                        <select className='name' value={select} onChange={(e) => setSelect(e.target.value)}>
                            <option value="">{dataTranslate?.data?.data["Departments"]}</option>
                            {dataDepartments?.data?.data?.map((item) => (
                                <option key={item?.id} value={item?.id}>{item?.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="athletes">
                        {select != "" ? dataStaffs?.data?.data?.results?.filter((c) => c?.department?.id == select)?.map((item) => (
                            <div key={item?.id} className="athlete pd-3 gap-2">
                                <img src={item?.image} alt="img" className="img round-1" />
                                <div className="texts gap-05">
                                    <p className="text who"><b>{item?.job_title}</b></p>
                                    <p className="text at_name mbot-1"><b>{item?.name}</b></p>
                                    <p className="text"><b>{dataTranslate?.data?.data["Birth date"]}:</b> {item?.birth_date}</p>
                                    <p className="text"><b>{dataTranslate?.data?.data["Email"]}:</b> <a href={`mailto:${item?.email}`}>{item?.email}</a></p>
                                    <p className="text"><b>{dataTranslate?.data?.data["Phone Number"]}:</b> {item?.phone}</p>
                                    <p className="text"><b>{dataTranslate?.data?.data["Days of admission"]}:</b> {item?.days_of_admission}</p>
                                </div>
                            </div>))
                            :
                            dataStaffs?.data?.data?.results?.map((item) => (
                                <div key={item?.id} className="athlete pd-3 gap-2">
                                    <img src={item?.image} alt="img" className="img round-1" />
                                    <div className="texts gap-05">
                                        <p className="text who"><b>{item?.job_title}</b></p>
                                        <p className="text at_name mbot-1"><b>{item?.name}</b></p>
                                        <p className="text"><b>{dataTranslate?.data?.data["Birth date"]}:</b> {item?.birth_date}</p>
                                        <p className="text"><b>{dataTranslate?.data?.data["Email"]}:</b> <a href={`mailto:${item?.email}`}>{item?.email}</a></p>
                                        <p className="text"><b>{dataTranslate?.data?.data["Phone Number"]}:</b> {item?.phone}</p>
                                        <p className="text"><b>{dataTranslate?.data?.data["Days of admission"]}:</b> {item?.days_of_admission}</p>
                                    </div>
                                </div>
                            ))}
                    </div>
                    <BottomPagination dataLength={Math.ceil(dataStaffs?.data?.data?.count / 10)} changeNavigate={changeSimplePageNavigate} />
                </div>
            </div>
            <Partners />
        </>
    )
}

export default Employees
