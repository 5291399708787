import React from 'react';
import Select from 'react-select';
import { useQuery } from 'react-query';
import { SearchIcon } from '../../../assets/svgImages';
import '../../../styles/additional/sportstype/Coaches.scss';
import BottomPagination from '../../pagination/BottomPagination';
import { fetchAthletes, fetchTranslate } from '../../../api/Api';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { useSportsTypeContext } from '../../../contexts/SportsTypeContext';

function Sportsmens() {

    // router options

    const path = useLocation();
    const [param, setParam] = useSearchParams();

    // API options

    const dataTranslate = useQuery('translate', fetchTranslate);
    const dataSportsmens = useQuery(['athletes', path.search], () => fetchAthletes(path.search));

    // useContext hook options

    const { changePageNavigate, handleSearch, changeGender, changeRegion, gender, regionOptions } = useSportsTypeContext();

    return (
        <div className="Coaches gap-3">
            <div className="filter gap-2 mtop-1 mbot-1">
                <div className="type_gender gap-1">
                    <label htmlFor="gender" className='text'>{dataTranslate?.data?.data["Male"]}</label>
                    <input type="checkbox" id="gender" checked={gender == 'female'} onChange={() => changeGender()} />
                    <label htmlFor="gender" className='badge'></label>
                    <label htmlFor="gender" className='text'>{dataTranslate?.data?.data["Female"]}</label>
                </div>
                <Select
                    value={regionOptions?.filter((c) => c.value == param.get('region'))}
                    options={regionOptions}
                    onChange={(e) => changeRegion(e)}
                    placeholder={`${dataTranslate?.data?.data["Region"]}`}
                    className='select text'
                />
                <div className="searching">
                    <input type="text" id='search6' className='search_input text pd-05' required placeholder={`${dataTranslate?.data?.data["search_name"]}`} value={param.get('name') ? param.get('name') : ""} onChange={(e) => handleSearch(e.target.value)} />
                    <label htmlFor='search6' className='icon'><SearchIcon /></label>
                </div>
            </div>
            <div className="athletes">
                {dataSportsmens?.data?.data?.results?.map((item) => (
                    <div key={item?.id} className="athlete pd-3 gap-2">
                        <img src={item?.image} alt="img" className="img round-1" />
                        <div className="texts gap-05">
                            <p className="text at_name mbot-1"><b>{item?.name}</b></p>
                            <p className="text"><b>{dataTranslate?.data?.data["direction"]}:</b> {item?.sport?.name}</p>
                            <p className="text"><b>{dataTranslate?.data?.data["Birth date"]}:</b> {item?.birth_date}</p>
                            <p className="text"><b>{dataTranslate?.data?.data["Athlete"]}:</b> {item?.name}</p>
                            <p className="text"><b>{dataTranslate?.data?.data["Region"]}:</b> {item?.region?.name}</p>
                            <Link to={`/sportsmeny/${item?.slug}`} className='link text mtop-1' onClick={() => window.scrollTo(0, 0)}>{dataTranslate?.data?.data["See more"]}...</Link>
                        </div>
                    </div>
                ))}
            </div>
            <BottomPagination dataLength={Math.ceil(dataSportsmens?.data?.data?.count / 10)} changeNavigate={changePageNavigate} />
        </div>
    )
}

export default Sportsmens
