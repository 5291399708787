import React from 'react';
import Select from 'react-select';
import { useQuery } from 'react-query';
import Partners from '../home/Partners';
import '../../styles/additional/Sportsmens.scss';
import { SearchIcon } from '../../assets/svgImages';
import SpinnerLoader from '../loaders/SpinnerLoader';
import BackImg from '../../assets/images/diving.webp';
import BottomPagination from '../pagination/BottomPagination';
import { fetchAthletes, fetchTranslate } from '../../api/Api';
import { useParentContext } from '../../contexts/ParentContext';
import { Link, useLocation, useSearchParams } from 'react-router-dom';

function Sportsmens() {

    // router options

    const path = useLocation();
    const [param, setParam] = useSearchParams();

    // API options

    const dataTranslate = useQuery('translate', fetchTranslate);
    const dataAthletes = useQuery(['athletes', path.search], () => fetchAthletes(path.search));

    // useContext hook options

    const { changePageNavigate, handleSearch, changeGender, changeRegion, gender, regionOptions } = useParentContext();

    return (
        <>
            <div className="Sportsmens parent">
                <div className="c1 wrapper">
                    <img src={BackImg} alt="back-img" className="back-img" />
                </div>
                <div className="c2 wrapper gap-3">
                    <h1 className="title">{dataTranslate?.data?.data["Athletes"]}</h1>
                    <div className="filter gap-2 mtop-1 mbot-1">
                        <div className="type_gender gap-1">
                            <label htmlFor="gender" className='text'>{dataTranslate?.data?.data["Male"]}</label>
                            <input type="checkbox" id="gender" checked={gender == 'female'} onChange={changeGender} />
                            <label htmlFor="gender" className='badge'></label>
                            <label htmlFor="gender" className='text'>{dataTranslate?.data?.data["Female"]}</label>
                        </div>
                        <Select
                            value={regionOptions?.filter((c) => c.value == param.get('region'))}
                            options={regionOptions}
                            onChange={(e) => changeRegion(e.value)}
                            placeholder={`${dataTranslate?.data?.data["Region"]}`}
                            className='select text'
                        />
                        <div className="searching">
                            <label htmlFor='search1' className='icon'><SearchIcon /></label>
                            <input type="text" id='search1' className='search_input text pd-05' required placeholder={`${dataTranslate?.data?.data["search_name"]}`} value={param.get('name') ? param.get('name') : ""} onChange={(e) => handleSearch(e.target.value)} />
                        </div>
                    </div>
                    <div className="athlete_table">
                        {dataAthletes?.isLoading ?
                            <SpinnerLoader />
                            :
                            <table>
                                <thead>
                                    <tr className='text'>
                                        <th className='pd-05'>{dataTranslate?.data?.data["Athlete"]}</th>
                                        <th className='pd-05'>{dataTranslate?.data?.data["Gender"]}</th>
                                        <th className='pd-05'>{dataTranslate?.data?.data["Birth date"]}</th>
                                        <th className='pd-05'>{dataTranslate?.data?.data["Region"]}</th>
                                        <th className='pd-05'>{dataTranslate?.data?.data["direction"]}</th>
                                        <th className='pd-05'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataAthletes?.data?.data?.results?.map((item) => (
                                        <tr key={item?.id} className='text'>
                                            <td className='pd-1'><div className='tool gap-1'><img src={item.image} alt="img" className="img" /> {item.name}</div></td>
                                            <td className='pd-1'>{item?.gender == 'male' ? `${dataTranslate?.data?.data["Male"]?.slice(0, 3)}.` : `${dataTranslate?.data?.data["Female"]?.slice(0, 3)}.`}</td>
                                            <td className='pd-1'>{item.birth_date}</td>
                                            <td className='pd-1'>{item?.region?.name}</td>
                                            <td className='pd-1'>{item?.sport?.name}</td>
                                            <td className='pd-1'><Link to={`${item.slug}`} onClick={() => window.scrollTo(0, 0)}>{dataTranslate?.data?.data["See more"]}...</Link></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        }
                    </div>
                    <BottomPagination dataLength={Math.ceil(dataAthletes?.data?.data?.count / 10)} changeNavigate={changePageNavigate} />
                </div>
            </div>
            <Partners />
        </>
    )
}

export default Sportsmens
