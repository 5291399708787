import React from 'react'
import { useQuery } from 'react-query';
import '../../../styles/additional/sportstype/Coaches.scss';
import { useLocation, useSearchParams } from 'react-router-dom';
import BottomPagination from '../../pagination/BottomPagination';
import { useParentContext } from '../../../contexts/ParentContext';
import { fetchLeaderships, fetchTranslate } from '../../../api/Api';

function LeaderShips() {

    // router options

    const path = useLocation();
    const [param, setParam] = useSearchParams();

    // API options

    const dataTranslate = useQuery('translate', fetchTranslate);
    const dataLeaderships = useQuery(['leaderships', path.search], () => fetchLeaderships(path.search));

    // useContext hook options

    const { changePageNavigate } = useParentContext();

    return (
        <div className="Coaches parent gap-3">
            <div className="athletes">
                {param.get('region') ? dataLeaderships?.data?.data?.results?.filter((c) => c?.branch?.region?.id == param.get('region'))?.map((item) => (
                    <div key={item?.id} className="athlete pd-3 gap-2">
                        <img src={item?.image} alt="img" className="img round-1" />
                        <div className="texts gap-05">
                            <p className="text who"><b>{item?.job_title}</b></p>
                            <p className="text at_name mbot-1"><b>{item?.name}</b></p>
                            <p className="text"><b>{dataTranslate?.data?.data["Birth date"]}:</b> {item?.birth_date}</p>
                            <p className="text"><b>{dataTranslate?.data?.data["Email"]}:</b> <a href={`mailto:${item?.email}`}>{item?.email}</a></p>
                            <p className="text"><b>{dataTranslate?.data?.data["Phone Number"]}:</b> {item?.phone}</p>
                            <p className="text"><b>{dataTranslate?.data?.data["Days of admission"]}:</b> {item?.days_of_admission}</p>
                        </div>
                    </div>
                )) :
                    dataLeaderships?.data?.data?.results?.map((item) => (
                        <div key={item?.id} className="athlete pd-3 gap-2">
                            <img src={item?.image} alt="img" className="img round-1" />
                            <div className="texts gap-05">
                                <p className="text who"><b>{item?.job_title}</b></p>
                                <p className="text at_name mbot-1"><b>{item?.name}</b></p>
                                <p className="text"><b>{dataTranslate?.data?.data["Birth date"]}:</b> {item?.birth_date}</p>
                                <p className="text"><b>{dataTranslate?.data?.data["Email"]}:</b> <a href={`mailto:${item?.email}`}>{item?.email}</a></p>
                                <p className="text"><b>{dataTranslate?.data?.data["Phone Number"]}:</b> {item?.phone}</p>
                                <p className="text"><b>{dataTranslate?.data?.data["Days of admission"]}:</b> {item?.days_of_admission}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
            <BottomPagination dataLength={Math.ceil(dataLeaderships?.data?.data?.count / 10)} changeNavigate={changePageNavigate} />
        </div>
    )
}

export default LeaderShips
