import React from 'react'
import Partners from '../home/Partners'
import { useQuery } from 'react-query';
import '../../styles/additional/Pools.scss';
import { useParams } from 'react-router-dom';
import BackImg from '../../assets/images/diving.webp';
import { fetchPoolsItem, fetchTranslate } from '../../api/Api';

function PoolsItem() {

    // router options

    const { id } = useParams();

    // API options

    const dataTranslate = useQuery('translate', fetchTranslate);
    const dataPoolsItem = useQuery(['branch-item', id], () => fetchPoolsItem(id));

    return (
        <>
            <div className="Pools PoolsItem parent">
                <div className="c1 wrapper">
                    <img src={BackImg} alt="back-img" className="back-img" />
                </div>
                <div className="c2 wrapper gap-3">
                    <h1 className="sub-title">{dataPoolsItem?.data?.data?.name}</h1>
                    <div className="item_infos gap-2">
                        <img src={dataPoolsItem?.data?.data?.image} alt="img" className="pool_img round-1-5" />
                        <div className="infos gap-1">
                            <p className="big-text"><b>{dataTranslate?.data?.data["Branch"]}:</b> {dataPoolsItem?.data?.data?.branch?.name}</p>
                            <p className="big-text"><b>{dataTranslate?.data?.data["direktor"]}:</b> {dataPoolsItem?.data?.data?.branch?.director}</p>
                            <p className="big-text"><b>{dataTranslate?.data?.data["Phone Number"]}:</b> {dataPoolsItem?.data?.data?.branch?.phone}</p>
                            <p className="big-text"><b>{dataTranslate?.data?.data["Email"]}:</b> <a href={`mailto:${dataPoolsItem?.data?.data?.branch?.email}`}>{dataPoolsItem?.data?.data?.branch?.email}</a></p>
                            <p className="big-text"><b>{dataTranslate?.data?.data["Address"]}:</b> {dataPoolsItem?.data?.data?.branch?.address}</p>
                        </div>
                    </div>
                    <p className="text desc">{dataPoolsItem?.data?.data?.description}</p>
                    <div className='location round-1' dangerouslySetInnerHTML={{ __html: dataPoolsItem?.data?.data?.location }} />
                </div>
            </div>
            <Partners />
        </>
    )
}

export default PoolsItem
