import React from 'react';
import News from './News';
import Images from './Images';
import Videos from './Videos';
import Interview from './Interview';
import { useQuery } from 'react-query';
import Partners from '../home/Partners';
import '../../styles/additional/Media.scss';
import { fetchTranslate } from '../../api/Api';
import { Link, useLocation } from 'react-router-dom';
import BackImg from '../../assets/images/diving.webp';
import { ImagesIcon, InterviewIcon, NewsIcon, VideoIcon } from '../../assets/svgImages';

function Media() {

    // router options

    const path = useLocation();

    // API options

    const dataTranslate = useQuery('translate', fetchTranslate);

    return (
        <>
            <div className="Media parent">
                <div className="c1">
                    <img src={BackImg} alt="back-img" className="back-img" />
                </div>
                <div className="wrapper c2 gap-3">
                    <h1 className="title">{dataTranslate?.data?.data["media"]}</h1>
                    <div className="categories gap-3">
                        <Link to={'/media-novosti'} className={`category gap-1 round-07 pd-1 ${path.pathname == '/media-novosti' && "active_category"}`}>
                            <div className="icon"><NewsIcon /></div>
                            <p className="text">{dataTranslate?.data?.data["News"]}</p>
                        </Link>
                        <Link to={'/media-interviu'} className={`category gap-1 round-07 pd-1 ${path.pathname == "/media-interviu" && "active_category"}`}>
                            <div className="icon"><InterviewIcon /></div>
                            <p className="text">{dataTranslate?.data?.data["Interviews"]}</p>
                        </Link>
                        <Link to={'/media-fotogalereia'} className={`category gap-1 round-07 pd-1 ${path.pathname == '/media-fotogalereia' && "active_category"}`}>
                            <div className="icon"><ImagesIcon /></div>
                            <p className="text">{dataTranslate?.data?.data["Images"]}</p>
                        </Link>
                        <Link to={'/media-video'} className={`category gap-1 round-07 pd-1 ${path.pathname == '/media-video' && "active_category"}`}>
                            <div className="icon"><VideoIcon /></div>
                            <p className="text">{dataTranslate?.data?.data["Videos"]}</p>
                        </Link>
                    </div>
                </div>
            </div>
            {path.pathname == '/media-novosti' &&
                <News />
            }
            {path.pathname == "/media-interviu" &&
                <Interview />
            }
            {path.pathname == '/media-fotogalereia' &&
                <Images />
            }
            {path.pathname == '/media-video' &&
                <Videos />
            }
            <Partners />
        </>
    )
}

export default Media
