import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import '../../styles/defaults/Footer.scss';
import Logo from '../../assets/images/logo.jpg';
import { fetchContacts, fetchRouters, fetchTranslate } from '../../api/Api';

function Footer() {

    // API options

    const dataRouters = useQuery('routers', fetchRouters);
    const dataContacts = useQuery('contacts', fetchContacts);
    const dataTranslate = useQuery('translate', fetchTranslate);

    // contacts options

    const [selectedId, setSelectedId] = useState("");

    useEffect(() => {
        setSelectedId(dataContacts?.data?.data[0]?.id)
    }, [dataContacts?.isLoading]);

    return (
        <div className="Footer parent gap-4">
            <div className="top_footer">
                <div className="left">
                    <div className="tools gap-2">
                        {dataRouters?.data?.data?.map((item) => (
                            item?.children?.length > 0 &&
                            <div key={item?.id} className="tool gap-05">
                                <Link to={`/${item?.slug}`} className="text tool_title" onClick={() => window.scrollTo(0, 0)}>{item?.title}</Link>
                                {item?.children?.map((c) => (
                                    <Link key={c?.id} to={`/${c?.slug}`} className='min-text' onClick={() => window.scrollTo(0, 0)}>{c?.title}</Link>
                                ))}
                            </div>
                        ))}
                        {dataRouters?.data?.data?.map((item) => (
                            item?.children?.length == 0 &&
                            <div key={item?.id} className="tool gap-05">
                                <Link to={`/${item?.slug}`} className="text tool_title" onClick={() => window.scrollTo(0, 0)}>{item?.title}</Link>
                                {item?.children?.map((c) => (
                                    <Link key={c?.id} to={`/${c?.slug}`} className='min-text mtop-1' onClick={() => window.scrollTo(0, 0)}>{c?.title}</Link>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div className="tegs mtop-4 gap-1">
                        <h3 className="big-text">Теги <span></span></h3>
                        <div className="bars gap-1">
                            <Link to="/" className="bar min-text pd-05 round-05">Конференция</Link>
                            <Link to="/" className="bar min-text pd-05 round-05">Плавание</Link>
                            <Link to="/" className="bar min-text pd-05 round-05">Сборы</Link>
                            <Link to="/" className="bar min-text pd-05 round-05">Конференция</Link>
                            <Link to="/" className="bar min-text pd-05 round-05">Календарь событий</Link>
                            <Link to="/" className="bar min-text pd-05 round-05">Плавание</Link>
                            <Link to="/" className="bar min-text pd-05 round-05">Сборы</Link>
                            <Link to="/" className="bar min-text pd-05 round-05">Плавание</Link>
                            <Link to="/" className="bar min-text pd-05 round-05">Конференция</Link>
                            <Link to="/" className="bar min-text pd-05 round-05">Календарь событий</Link>
                            <Link to="/" className="bar min-text pd-05 round-05">Сборы</Link>
                        </div>
                    </div>
                </div>
                <div className="right pd-4 gap-1 round-1">
                    <h1 className="name cont">Контакты</h1>
                    <select className="selection text mtop-1 mbot-1" value={selectedId} onChange={(e) => setSelectedId(e.target.value)}>
                        {dataContacts?.data?.data?.map((item) => (
                            <option key={item?.id} value={item?.id}>{item?.district}</option>
                        ))}
                    </select>
                    {dataContacts?.data?.data?.filter((c) => c?.id == selectedId)?.map((item) => (
                        <div key={item?.id} className="childs gap-1">
                            <p className="text">{dataTranslate?.data?.data["Address"]}: {item?.address}</p>
                            <p className="text tel">{dataTranslate?.data?.data["Phone Number"]}: <a href={`tel:${item?.phone}`}><span className='name'>{item?.phone}</span></a></p>
                            <p className="text email">{dataTranslate?.data?.data["Email"]}: <a href={`mailto:${item?.email}`}>{item?.email}</a></p>
                        </div>
                    ))}
                </div>
            </div>
            <div className="bottom_footer gap-1">
                <div className="left gap-1">
                    <Link to="/" className='logo' onClick={() => window.scrollTo(0, 0)}>
                        <img src={Logo} alt="logo" className="img" />
                    </Link>
                    <p className="text">{dataTranslate?.data?.data["fed_uzb"]}</p>
                </div>
                <p className="right min-text">{dataTranslate?.data?.data["all_rights"]} &#169; 2023</p>
            </div>
        </div>
    )
}

export default Footer
