import React from 'react';
import ReactPaginate from 'react-paginate';
import '../../styles/pagination/BottomPagination.scss';

function BottomPagination({ dataLength, changeNavigate }) {

    // pagination options

    function handlePageClick(event) {
        changeNavigate(event.selected % dataLength + 1);
    };

    return (
        <div className="BottomPagination gap-05">
            {dataLength > 1 &&
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={window.innerWidth > 767 ? 5 : 3}
                    pageCount={dataLength ? Math.ceil(dataLength) : 10}
                    previousLabel="<"
                    renderOnZeroPageCount={3}
                />
            }
        </div>
    )
}

export default BottomPagination
