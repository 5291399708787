import React from 'react';
import { useQuery } from 'react-query';
import Partners from '../home/Partners';
import '../../styles/additional/NationalTeam.scss';
import BackImg from '../../assets/images/diving.webp';
import BottomPagination from '../pagination/BottomPagination';
import { fetchAthletes, fetchTranslate } from '../../api/Api';
import { useParentContext } from '../../contexts/ParentContext';
import { Link, useLocation, useSearchParams } from 'react-router-dom';

function NationalTeam() {

    // router options

    const path = useLocation();
    const [param, setParam] = useSearchParams();

    // API options

    const dataTranslate = useQuery('translate', fetchTranslate);
    const dataAthletes = useQuery(['athletes', path.search], () => fetchAthletes(path.search));

    // useContext hook options

    const { changeTeamPageNavigate, changeTeamGender, changeTeamType, gender } = useParentContext();

    return (
        <>
            <div className="NationalTeam parent">
                <div className="c1 wrapper">
                    <img src={BackImg} alt="back-img" className="back-img" />
                </div>
                <div className="c2 wrapper gap-3">
                    <h1 className="title">{dataTranslate?.data?.data["Team"]}</h1>
                    <div className="filters gap-2">
                        <button className={`filter_btn text scale-05 ${param.get('team__type') == 'youth' && "active_btn"}`} onClick={() => changeTeamType('youth')}>{dataTranslate?.data?.data["Youth team"]}</button>
                        <button className={`filter_btn text scale-05 ${param.get('team__type') == 'junior' && "active_btn"}`} onClick={() => changeTeamType('junior')}>{dataTranslate?.data?.data["Junior team"]}</button>
                        <button className={`filter_btn text scale-05 ${param.get('team__type') == 'national' && "active_btn"}`} onClick={() => changeTeamType('national')}>{dataTranslate?.data?.data["National team"]}</button>
                    </div>
                    <div className="athletes gap-2 mtop-1">
                        <div className="category gap-1">
                            <p className={`link text ${gender == 'male' && "active_link"}`} onClick={() => changeTeamGender()}>{dataTranslate?.data?.data["Male"]}</p>
                            <p className={`link text ${gender == 'female' && "active_link"}`} onClick={() => changeTeamGender()}>{dataTranslate?.data?.data["Female"]}</p>
                        </div>
                        {dataAthletes?.data?.data?.results?.map((item) => (
                            <Link key={item?.id} to={`/sportsmeny/${item?.slug}`} className="athlete" onClick={() => window.scrollTo(0, 0)}>
                                <img src={item?.image} alt="img" className="img" />
                                <div className="cont pd-1">
                                    <p className="text">{item?.name}</p>
                                </div>
                            </Link>
                        ))}
                    </div>
                    <BottomPagination dataLength={Math.ceil(dataAthletes?.data?.data?.count / 10)} changeNavigate={changeTeamPageNavigate} />
                </div>
            </div >
            <Partners />
        </>
    )
}

export default NationalTeam
