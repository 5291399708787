import React from 'react'
import { useQuery } from 'react-query';
import '../../../styles/additional/Pools.scss';
import { fetchPools, fetchTranslate } from '../../../api/Api';
import BottomPagination from '../../pagination/BottomPagination';
import { useParentContext } from '../../../contexts/ParentContext';
import { Link, useLocation, useSearchParams } from 'react-router-dom';

function Pools() {

    // router options

    const path = useLocation();
    const [param, setParam] = useSearchParams();

    // API options

    const dataTranslate = useQuery('translate', fetchTranslate);
    const dataPools = useQuery(['pools', path.search], () => fetchPools(path.search));

    // useContext hook options

    const { changePageNavigate } = useParentContext();

    return (
        <div className="Pools parent gap-3">
            <div className="cards mbot-3">
                {param.get('region') ? dataPools?.data?.data?.results?.filter((c) => c?.branch?.region?.id == param.get('region'))?.map((item) => (
                    <Link key={item?.id} to={`/o-federatsii-basseiny/${item?.id}`} className="branch gap-1-5 round-1 pd-2-5" onClick={() => window.scrollTo(0, 0)}>
                        <img src={item?.image} alt="img" className="img round-07" />
                        <div className="texts gap-05">
                            <p className="text">{item?.name}</p>
                            <p className="min-text mtop-05"><b>{dataTranslate?.data?.data["Phone Number"]}:</b>  {item?.branch?.phone}</p>
                            <p className="min-text"><b>{dataTranslate?.data?.data["Address"]}:</b> {item?.branch?.region?.name}</p>
                        </div>
                    </Link>
                )) :
                    dataPools?.data?.data?.results?.map((item) => (
                        <Link key={item?.id} to={`/o-federatsii-basseiny/${item?.id}`} className="branch gap-1-5 round-1 pd-2-5" onClick={() => window.scrollTo(0, 0)}>
                            <img src={item?.image} alt="img" className="img round-07" />
                            <div className="texts gap-05">
                                <p className="text">{item?.name}</p>
                                <p className="min-text mtop-05"><b>{dataTranslate?.data?.data["Phone Number"]}:</b>  {item?.branch?.phone}</p>
                                <p className="min-text"><b>{dataTranslate?.data?.data["Address"]}:</b> {item?.branch?.region?.name}</p>
                            </div>
                        </Link>
                    ))
                }
            </div>
            <BottomPagination dataLength={Math.ceil(dataPools?.data?.data?.count / 10)} changeNavigate={changePageNavigate} />
        </div>
    )
}

export default Pools
