import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import '../../styles/defaults/Navbar.scss';
import Logo from '../../assets/images/logo.jpg';
import { Link, useNavigate } from 'react-router-dom';
import { fetchRouters, fetchSearchNews, fetchSocialLinks, fetchSports, fetchTranslate } from '../../api/Api';
import { CloseIcon, FacebookIcon, InstagramIcon, MenuIcon, SearchIcon, TelegramIcon } from '../../assets/svgImages';

function Navbar({ changeContactModal }) {

    // router options

    const navigate = useNavigate();

    // scroll options

    useEffect(() => {
        window.addEventListener("scroll", () => {
            const nav = document.getElementById("nav");
            nav.classList.toggle("scrolling_navbar", window.scrollY > 10);
        });
    }, []);

    // responsive menu options

    const [showMenu, setShowMenu] = useState(false);
    const [showMenuBar, setShowMenuBar] = useState(false);

    // API options

    const [searchValue, setSearchValue] = useState("");

    const dataRouters = useQuery('routers', fetchRouters);
    const dataSports = useQuery('all-sports', fetchSports);
    const dataTranslate = useQuery('translate', fetchTranslate);
    const dataSocialLinks = useQuery('social-links', fetchSocialLinks);
    const dataNews = useQuery(['search-news', searchValue], () => fetchSearchNews(searchValue));

    // language options

    function changeLanguage(item) {
        window.location.reload();
        sessionStorage.setItem('language', item);
    };

    let lang = sessionStorage.getItem('language');

    // search options

    const [showSearch, setShowSearch] = useState(false);

    return (
        <div className="Navbar" id='nav'>
            <nav className="navbar">
                <div className="top_navbar">
                    <div className="left gap-3">
                        <Link to="/" className='logo' onClick={() => window.scrollTo(0, 0)}>
                            <img src={Logo} alt="logo" className="logo_img" />
                        </Link>
                        <div className="language gap">
                            <button className={`lang pd-05 round-05 text ${lang ? lang == "en" && "active_lang" : ""}`} onClick={() => changeLanguage("en")}>EN</button>
                            <button className={`lang pd-05 round-05 text ${lang ? lang == "uz" && "active_lang" : ""}`} onClick={() => changeLanguage("uz")}>UZ</button>
                            <button className={`lang pd-05 round-05 text ${lang ? lang == "ru" && "active_lang" : ""}`} onClick={() => changeLanguage("ru")}>RU</button>
                        </div>
                    </div>
                    <div className={`links gap-2-5 ${showMenu && "show_menu"}`}>
                        {dataRouters?.data?.data?.map((item) => (
                            item?.on_main &&
                            <div key={item?.id} className='text link' onClick={() => item?.children?.length == 0 && [navigate(`/${item?.slug}`), window.scrollTo(0, 0)]}>
                                {item?.title}
                                {item?.children?.length > 0 &&
                                    <div className="link_bar">
                                        <div className="cont_link pd-3 round-2">
                                            {item?.children?.map((c) => (
                                                <Link key={c?.id} to={c?.slug == 'o-federatsii-zapis-na-sektsii' ? `/` : `/${c?.slug}`} className="bar-link text" onClick={() => c?.slug == "o-federatsii-zapis-na-sektsii" ? changeContactModal() : window.scrollTo(0, 0)}>{c?.title}</Link>
                                            ))}
                                        </div>
                                    </div>
                                }
                            </div>
                        ))}
                        <button className="more_btn text" onClick={() => setShowMenuBar(true)}>{dataTranslate?.data?.data["more"]}...</button>
                        <div className="socials gap-2">
                            <a href={dataSocialLinks?.data?.data?.facebook_link} target='_blank' className="icon-1"><FacebookIcon /></a>
                            <a href={dataSocialLinks?.data?.data?.instagram_link} target='_blank' className="icon-1"><InstagramIcon /></a>
                            <a href={dataSocialLinks?.data?.data?.telegram_link} target='_blank' className="icon-1" title='@aquatics_uz'><TelegramIcon /></a>
                        </div>
                        <div className="close_icon" onClick={() => setShowMenu(false)}><CloseIcon /></div>
                    </div>
                    <button className="submit_btn text" onClick={changeContactModal}>{dataTranslate?.data?.data["submit"]}</button>
                    <div className="menu_icon" onClick={() => setShowMenuBar(true)}><MenuIcon /></div>
                </div>
                <div className="bottom_navbar">
                    <div className="socials gap-2">
                        <a href={dataSocialLinks?.data?.data?.facebook_link} target='_blank' className="icon-1"><FacebookIcon /></a>
                        <a href={dataSocialLinks?.data?.data?.instagram_link} target='_blank' className="icon-1"><InstagramIcon /></a>
                        <a href={dataSocialLinks?.data?.data?.telegram_link} target='_blank' className="icon-1" title='@aquatics_uz'><TelegramIcon /></a>
                    </div>
                </div>
            </nav>
            <div className={`menu_bar gap-5 ${showMenuBar && "show_menu_bar"}`}>
                <div className="searching">
                    <input type="search" name="search" id="search" className='big-text pd-1' placeholder={`${dataTranslate?.data?.data["search"]}`} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} onClick={() => setShowSearch(true)} />
                    {showSearch &&
                        <div className="serch_bar">
                            {dataNews?.data?.data?.map((item) => (
                                <Link to={`/media-novosti/${item?.id}`} key={item?.id} className='search_link gap-1 pd-1' onClick={() => [setShowMenuBar(false), window.scrollTo(0, 0)]}>
                                    <div className="icn"><SearchIcon /></div>
                                    <p className="text">{item?.title}</p>
                                </Link>
                            ))}
                        </div>
                    }
                    {showSearch &&
                        <div className="contrast" onClick={() => setShowSearch(false)}></div>
                    }
                </div>
                <div className="links gap-5">
                    {dataRouters?.data?.data?.map((item) => (
                        item?.children?.length > 0 &&
                        <div key={item?.id} className="tool gap-05">
                            <div className="tool-title text" onClick={() => setShowMenuBar(false)}>{item?.title}</div>
                            {item?.children?.map((c) => (
                                c?.slug == 'o-federatsii-zapis-na-sektsii' ?
                                    <Link key={c?.id} to={`/`} className="tool-link text" onClick={() => c?.slug == "o-federatsii-zapis-na-sektsii" ? [changeContactModal(), setShowMenuBar(false)] : [setShowMenuBar(false), window.scrollTo(0, 0)]}>{c?.title}</Link>
                                    :
                                    item?.slug == 'o-federatsii' || item?.slug == 'media' ?
                                        <Link key={c?.id} to={`/${c?.slug}`} className="tool-link text" onClick={() => c?.slug == "o-federatsii-zapis-na-sektsii" ? [changeContactModal(), setShowMenuBar(false)] : [setShowMenuBar(false), window.scrollTo(0, 0)]}>{c?.title}</Link>
                                        :
                                        dataSports?.data?.data?.map((j) => (
                                            item?.slug == j?.slug &&
                                            <Link key={c?.id} to={`/${c?.slug}?sport=${j?.id}`} className="tool-link text" onClick={() => c?.slug == "o-federatsii-zapis-na-sektsii" ? [changeContactModal(), setShowMenuBar(false)] : [setShowMenuBar(false), window.scrollTo(0, 0)]}>{c?.title}</Link>
                                        ))
                            ))}
                        </div>
                    ))}
                    {dataRouters?.data?.data?.map((item) => (
                        item?.children?.length == 0 &&
                        <div key={item?.id} className="tool gap-05">
                            <Link to={`/${item?.slug}`} className="tool-title text" onClick={() => [setShowMenuBar(false), window.scrollTo(0, 0)]}>{item?.title}</Link>
                            {item?.children?.map((c) => (
                                <Link key={c?.id} to={c?.slug == 'o-federatsii-zapis-na-sektsii' ? `/` : `/${c?.slug}`} className="tool-link text" onClick={() => c?.slug == "o-federatsii-zapis-na-sektsii" ? [changeContactModal(), setShowMenuBar(false)] : [setShowMenuBar(false), window.scrollTo(0, 0)]}>{c?.title}</Link>
                            ))}
                        </div>
                    ))}
                </div>
                <div className="close_icon" onClick={() => setShowMenuBar(false)}><CloseIcon /></div>
            </div>
        </div>
    )
}

export default Navbar
