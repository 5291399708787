import React from 'react';
import { useQuery } from 'react-query';
import Partners from '../home/Partners';
import '../../styles/additional/About.scss';
import BackImg from '../../assets/images/diving.webp';
import { fetchAbout, fetchTranslate } from '../../api/Api';

function About() {

    // API options

    const dataAbout = useQuery('about', fetchAbout);
    const dataTranslate = useQuery('translate', fetchTranslate);

    return (
        <>
            <div className="About parent">
                <div className="c1 wrapper">
                    <img src={BackImg} alt="back-img" className="back-img" />
                </div>
                <div className="c2 wrapper gap-2">
                    <h1 className="sub-title">{dataTranslate?.data?.data["About Federation"]}</h1>
                    <p className="text"><b>{dataTranslate?.data?.data["Email"]}:</b> {dataAbout?.data?.data?.email}</p>
                    <p className="text"><b>{dataTranslate?.data?.data["Phone Number"]}:</b> {dataAbout?.data?.data?.phone_number}</p>
                    <p className="text"><b>{dataTranslate?.data?.data["Address"]}:</b> {dataAbout?.data?.data?.address}</p>
                    <p className="text">{dataAbout?.data?.data?.text}</p>
                </div>
            </div>
            <Partners />
        </>
    )
}

export default About
