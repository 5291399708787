import React from 'react';
import { useQuery } from 'react-query';
import '../../styles/additional/Records.scss';
import { MedalIcon } from '../../assets/svgImages';
import { Link, useLocation } from 'react-router-dom';
import { fetchRecords, fetchTranslate } from '../../api/Api';
import BottomPagination from '../pagination/BottomPagination';
import { useParentContext } from '../../contexts/ParentContext';

function Records() {

    // router options

    const path = useLocation();

    // API options

    const dataTranslate = useQuery('translate', fetchTranslate);
    const dataRecords = useQuery(['records', path.search], () => fetchRecords(path.search));

    // useContext hook options

    const { changeSimplePageNavigate } = useParentContext();

    return (
        <div className="Records parent">
            <div className="wrapper gap-2">
                <h1 className="sub-title">{dataTranslate?.data?.data["Records"]}</h1>
                <div className="cards gap-3">
                    {dataRecords?.data?.data?.results?.map((item) => (
                        <Link key={item?.id} to={`${item?.id}`} className='record gap-05 pd-1 round-1' onClick={() => window.scrollTo(0, 0)}>
                            <div className="icon"><MedalIcon /></div>
                            <p className="record_name text mtop-05">{item?.name}</p>
                            <p className="min-text desc">{item?.description}</p>
                        </Link>
                    ))}
                </div>
                <BottomPagination dataLength={Math.ceil(dataRecords?.data?.data?.count / 10)} changeNavigate={changeSimplePageNavigate} />
            </div>
        </div>
    )
}

export default Records
