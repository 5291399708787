import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import '../../styles/home/WaterPolo.scss';
import { fetchTranslate } from '../../api/Api';
import Img from '../../assets/images/polo.jpeg';
import { MasterIcon, SportsmenIcon } from '../../assets/svgImages';

function WaterPolo({ changeContactModal }) {

    // API options

    const dataTranslate = useQuery('translate', fetchTranslate);

    return (
        <div className="WaterPolo parent">
            <div className="wrapper">
                <div className="circle"></div>
                <div className="infos">
                    <div className="imgs">
                        <img src={Img} alt="img" className="img" />
                    </div>
                    <div className="texts gap-2">
                        <h1 className="sub-title">{dataTranslate?.data?.data["water_polo"]}</h1>
                        <p className="text desc">{dataTranslate?.data?.data["water_polo_text"]}</p>
                        <div className="icons gap-2">
                            <div className="info gap-1">
                                <div className="icon"><SportsmenIcon /></div>
                                <p className="text">{dataTranslate?.data?.data["Athletes"]}</p>
                            </div>
                            <div className="info gap-1">
                                <div className="icon"><MasterIcon /></div>
                                <p className="text">{dataTranslate?.data?.data["Coaches"]}</p>
                            </div>
                        </div>
                        <div className="btns gap-2 mtop-1">
                            <Link to="/" className="submit_btn text scale-05" onClick={changeContactModal}>{dataTranslate?.data?.data["submit"]}</Link>
                            <Link to="/vodnoe-polo-sorevnovaniia" className='explore text' onClick={() => window.scrollTo(0, 0)}>{dataTranslate?.data?.data["See more"]}...</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WaterPolo
