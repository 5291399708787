import React, { useState } from 'react';
import { useQuery } from 'react-query';
import '../../styles/home/KidsFederation.scss';
import KidsImg from '../../assets/images/kids.png';
import { CloseIcon, UserIcon } from '../../assets/svgImages';
import { fetchChildren, fetchTranslate } from '../../api/Api';

function KidsFederation() {

    // API options

    const dataChildren = useQuery('children', fetchChildren);
    const dataTranslate = useQuery('translate', fetchTranslate);

    // modal options

    const [dataModal, setDataModal] = useState(null);

    return (
        <>
            <div className="KidsFederation parent">
                <img src={KidsImg} alt="img" className="kids_img" />
                <div className="wrapper gap-3">
                    <h1 className="sub-title">{dataTranslate?.data?.data["Children in Federation"]}</h1>
                    <div className="infos">
                        {dataChildren?.data?.data?.map((item) => (
                            <div key={item?.id} className="info round-1 pd-2 gap-1" onClick={() => setDataModal({ name: item?.title, description: item?.description })}>
                                <div className="icon"><UserIcon /></div>
                                <h3 className="big-text">{item?.title}</h3>
                                <p className="text desc">{item?.description}</p>
                                <p className='explore text'>{dataTranslate?.data?.data["See more"]}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {dataModal != null &&
                <div className="KidsModal">
                    <div className="child pd-3 round-1">
                        <div className="icon" onClick={() => setDataModal(null)}>
                            <CloseIcon />
                        </div>
                        <h1 className="name">{dataModal?.name}</h1>
                        <p className="big-text mtop-1">{dataModal?.description}</p>
                    </div>
                    <div className="contrast" onClick={() => setDataModal(null)}></div>
                </div>
            }
        </>
    )
}

export default KidsFederation
