import axios from "axios";
import { ABOUT_URL, ATHLETES_URL, BRANCHES_URL, CHILDREN_URL, COACHES_URL, CONTACTS_URL, DEPARTMENTS_URL, DOCUMENTS_URL, DOPING_URL, GALLERIES_URL, INTERVIEWS_URL, LEADERSHIPS_URL, NEWS_URL, ORGANIZATIONS_URL, PARTNERS_URL, POOLS_URL, RECORDS_URL, REFEREES_URL, REGIONS_URL, ROUTERS_URL, RULES_URL, SOCIAL_LINKS_URL, SPORTS_URL, STAFF_URL, TEAMS_URL, TOURNAMENTS_URL, TRANSLATIONS_URL, VIDEOS_URL, YEARS_URL } from "./ApiUrl";

// API of News

export const fetchNews = (filter) => {
    return axios.get(`${NEWS_URL}${filter}`)
};

// API of SearchNews

export const fetchSearchNews = (filter) => {
    return axios.get(`${NEWS_URL}-search/?search_query=${filter}`)
};

// API of NewItem

export const fetchNewItem = (filter) => {
    return axios.get(`${NEWS_URL}/${filter}`)
};

// API of Videos

export const fetchVideos = () => {
    return axios.get(VIDEOS_URL)
};

// API of Children

export const fetchChildren = () => {
    return axios.get(CHILDREN_URL)
};

// API of Interviews

export const fetchInterviews = () => {
    return axios.get(INTERVIEWS_URL)
};

// API of InterviewItem

export const fetchInterviewItem = (filter) => {
    return axios.get(`${INTERVIEWS_URL}/${filter}`)
};

// API of SocialLinks

export const fetchSocialLinks = () => {
    return axios.get(SOCIAL_LINKS_URL)
};

// API of Galleries

export const fetchGalleries = (filter) => {
    return axios.get(`${GALLERIES_URL}${filter}`)
};

// API of GalleriesItem

export const fetchGalleriesItem = (filter) => {
    return axios.get(`${GALLERIES_URL}/${filter}`)
};

// API of Coaches

export const fetchCoaches = (filter) => {
    return axios.get(`${COACHES_URL}${filter}`)
};

// API of CoachesItem

export const fetchCoachesItem = (filter) => {
    return axios.get(`${COACHES_URL}/${filter}`)
};

// API of Judges

export const fetchJudges = (filter) => {
    return axios.get(`${REFEREES_URL}${filter}`)
};

// API of JudgesItem

export const fetchJudgesItem = (filter) => {
    return axios.get(`${REFEREES_URL}/${filter}`)
};

// API of Referees

export const fetchReferees = () => {
    return axios.get(REFEREES_URL)
};

// API of Athletes

export const fetchAthletes = (filter) => {
    return axios.get(`${ATHLETES_URL}${filter}`)
};

// API of Records

export const fetchRecords = (filter) => {
    return axios.get(`${RECORDS_URL}${filter}`)
};

// API of Years

export const fetchYears = () => {
    return axios.get(YEARS_URL)
};

// API of Tournaments

export const fetchTournaments = (filter) => {
    return axios.get(`${TOURNAMENTS_URL}${filter}`)
};

// API of TournamentItem

export const fetchTournamentItem = (filter) => {
    return axios.get(`${TOURNAMENTS_URL}/${filter}`)
};

// API of Teams

export const fetchTeams = () => {
    return axios.get(TEAMS_URL)
};

// API of Branches

export const fetchBranches = (filter) => {
    return axios.get(`${BRANCHES_URL}${filter}`)
};

// API of BranchItem

export const fetchBranchItem = (filter) => {
    return axios.get(`${BRANCHES_URL}/${filter}`)
};

// API of Departments

export const fetchDepartments = () => {
    return axios.get(DEPARTMENTS_URL)
};

// API of Documents

export const fetchDocuments = (filter) => {
    return axios.get(`${DOCUMENTS_URL}${filter}`)
};

// API of Organizations

export const fetchOrganizations = () => {
    return axios.get(ORGANIZATIONS_URL)
};

// API of Pools

export const fetchPools = (filter) => {
    return axios.get(`${POOLS_URL}${filter}`)
};

// API of PoolsItem

export const fetchPoolsItem = (filter) => {
    return axios.get(`${POOLS_URL}/${filter}`)
};

// API of Rules

export const fetchRules = (filter) => {
    return axios.get(`${RULES_URL}${filter}`)
};

// API of Regions

export const fetchRegions = () => {
    return axios.get(REGIONS_URL)
};

// API of Staff

export const fetchStaff = (filter) => {
    return axios.get(`${STAFF_URL}${filter}`)
};

// API of Doping

export const fetchDoping = (filter) => {
    return axios.get(`${DOPING_URL}${filter}`)
};

// API of Leaderships

export const fetchLeaderships = () => {
    return axios.get(LEADERSHIPS_URL)
};

// API of Partners

export const fetchPartners = () => {
    return axios.get(PARTNERS_URL)
};

// API of Routers

export const fetchRouters = () => {
    return axios.get(ROUTERS_URL)
};

// API of Contacts

export const fetchContacts = () => {
    return axios.get(CONTACTS_URL)
};

// API of Sports

export const fetchSports = () => {
    return axios.get(SPORTS_URL)
};

// API of About

export const fetchAbout = () => {
    return axios.get(ABOUT_URL)
};

// API of Translate

export const fetchTranslate = () => {
    return axios.get(TRANSLATIONS_URL)
};