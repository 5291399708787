import React, { useState } from 'react';
import HomeRouter from './routes/HomeRouter';
import About from './components/others/About';
import Media from './components/others/Media';
import Pools from './components/others/Pools';
import Doping from './components/others/Doping';
import Judges from './components/others/Judges';
import Navbar from './components/defaults/Navbar';
import NewItem from './components/others/NewItem';
import Coaches from './components/others/Coaches';
import Records from './components/others/Records';
import Partners from './components/home/Partners';
import Footer from './components/defaults/Footer';
import Contacts from './components/others/Contacts';
import Branches from './components/others/Branches';
import Employees from './components/others/Employees';
import PoolsItem from './components/others/PoolsItem';
import Documents from './components/others/Documents';
import SportsType from './components/others/SportsType';
import RecordItem from './components/others/RecordItem';
import Sportsmens from './components/others/Sportsmens';
import JudgesItem from './components/others/JudgesItem';
import Leaderships from './components/others/Leaderships';
import CoachesItem from './components/others/CoachesItem';
import Competetions from './components/others/Competetions';
import NationalTeam from './components/others/NationalTeam';
import ContactModal from './components/modals/ContactModal';
import FullCalendar from './components/defaults/FullCalendar';
import SportSmenItem from './components/others/SportSmenItem';
import InterviewItem from './components/others/InterviewItem';
import GalleriesItem from './components/others/GalleriesItem';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ParentContextProvider } from './contexts/ParentContext';
import CompetetionItem from './components/others/CompetetionItem';

function App() {

  // contact modal options

  const [showContactModal, setShowContactModal] = useState(false);

  function changeContactModal() {
    setShowContactModal(!showContactModal);
  };

  // react-query options

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ParentContextProvider>
          <Navbar changeContactModal={changeContactModal} />
          <FullCalendar />
          <Routes>
            <Route exact path='/' element={<HomeRouter changeContactModal={changeContactModal} />} />
            <Route path='/media-novosti/:id' element={<NewItem />} />
            <Route path='/trenery' element={<Coaches />} />
            <Route path='/trenery/:id' element={<CoachesItem />} />
            <Route path='/sorevnovaniia' element={<Competetions />} />
            <Route path='/sorevnovaniia/:id' element={<CompetetionItem />} />
            <Route path='/kontakty' element={<Contacts />} />
            <Route path='/o-federatsii' element={<About />} />
            <Route path='/o-federatsii-kontakty' element={<Contacts />} />
            <Route path='/o-federatsii-filialy-federatsii' element={<Branches />} />
            <Route path='/o-federatsii-filialy-federatsii/:id' element={<Branches />} />
            <Route path='/o-federatsii-rukovodstvo' element={<Leaderships />} />
            <Route path='/o-federatsii-sotrudniki' element={<Employees />} />
            <Route path='/o-federatsii-basseiny' element={<Pools />} />
            <Route path='/o-federatsii-basseiny/:id' element={<PoolsItem />} />
            <Route path='/o-federatsii-partnery-i-sponsory' element={<Partners />} />
            <Route path='/rekordy' element={<Records />} />
            <Route path='/rekordy/:id' element={<RecordItem />} />

            <Route path='/vodnoe-polo-sorevnovaniia' element={<SportsType />} />
            <Route path='/vodnoe-polo-sportsmeny' element={<SportsType />} />
            <Route path='/vodnoe-polo-trenery' element={<SportsType />} />
            <Route path='/vodnoe-polo-sudi' element={<SportsType />} />
            <Route path='/vodnoe-polo-pravila' element={<SportsType />} />
            <Route path='/vodnoe-polo-sbornye' element={<SportsType />} />

            <Route path='/sinkhronnoe-plavanie-sorevnovaniia' element={<SportsType />} />
            <Route path='/sinkhronnoe-plavanie-sportsmeny' element={<SportsType />} />
            <Route path='/sinkhronnoe-plavanie-trenery' element={<SportsType />} />
            <Route path='/sinkhronnoe-plavanie-sudi' element={<SportsType />} />
            <Route path='/sinkhronnoe-plavanie-pravila' element={<SportsType />} />
            <Route path='/sinkhronnoe-plavanie-sbornye' element={<SportsType />} />

            <Route path='/pryzhki-v-vodu-sorevnovaniia' element={<SportsType />} />
            <Route path='/pryzhki-v-vodu-sportsmeny' element={<SportsType />} />
            <Route path='/pryzhki-v-vodu-trenery' element={<SportsType />} />
            <Route path='/pryzhki-v-vodu-sudi' element={<SportsType />} />
            <Route path='/pryzhki-v-vodu-pravila' element={<SportsType />} />
            <Route path='/pryzhki-v-vodu-sbornye' element={<SportsType />} />

            <Route path='/plavanie-sorevnovaniia' element={<SportsType />} />
            <Route path='/plavanie-sportsmeny' element={<SportsType />} />
            <Route path='/plavanie-trenery' element={<SportsType />} />
            <Route path='/plavanie-sudi' element={<SportsType />} />
            <Route path='/plavanie-pravila' element={<SportsType />} />
            <Route path='/plavanie-sbornye' element={<SportsType />} />

            <Route path='/media' element={<Media />} />
            <Route path='/media-novosti' element={<Media />} />
            <Route path='/media-interviu' element={<Media />} />
            <Route path='/media-fotogalereia' element={<Media />} />
            <Route path='/media-fotogalereia/:id' element={<GalleriesItem />} />
            <Route path='/media-video' element={<Media />} />
            <Route path='/media/:id' element={<Media />} />
            <Route path='/doping' element={<Doping />} />
            <Route path='/sbornaia' element={<NationalTeam />} />
            <Route path='/sportsmeny' element={<Sportsmens />} />
            <Route path='/sportsmeny/:id' element={<SportSmenItem />} />
            <Route path='/media-interviu/:id' element={<InterviewItem />} />
            <Route path='/sudi' element={<Judges />} />
            <Route path='/sudi/:id' element={<JudgesItem />} />
            <Route path='/dokumenty' element={<Documents />} />
          </Routes>
          <Footer />
          {showContactModal &&
            <ContactModal changeContactModal={changeContactModal} />
          }
        </ParentContextProvider>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App