import React from 'react';
import { useQuery } from 'react-query';
import Partners from '../home/Partners';
import '../../styles/additional/Leaderships.scss';
import BackImg from '../../assets/images/diving.webp';
import { fetchLeaderships, fetchTranslate } from '../../api/Api';

function Leaderships() {

    // API options

    const dataTranslate = useQuery('translate', fetchTranslate);
    const dataLeaderships = useQuery('leaderships-o', fetchLeaderships);

    return (
        <>
            <div className="Leaderships parent">
                <div className="c1 wrapper">
                    <img src={BackImg} alt="back-img" className="back-img" />
                </div>
                <div className="c2 wrapper gap-3">
                    <h1 className="title">{dataTranslate?.data?.data["management"]}</h1>
                    <div className="athletes">
                        {dataLeaderships?.data?.data?.map((item) => (
                            <div key={item?.id} className="athlete pd-3 gap-2">
                                <img src={item?.image} alt="img" className="img round-1" />
                                <div className="texts gap-05">
                                    <p className="text who"><b>{item?.job_title}</b></p>
                                    <p className="text at_name mbot-1"><b>{item?.name}</b></p>
                                    <p className="text"><b>{dataTranslate?.data?.data["Birth date"]}:</b> {item?.birth_date}</p>
                                    <p className="text"><b>{dataTranslate?.data?.data["Email"]}:</b> <a href={`mailto:${item?.email}`}>{item?.email}</a></p>
                                    <p className="text"><b>{dataTranslate?.data?.data["Phone Number"]}:</b> {item?.phone}</p>
                                    <p className="text"><b>{dataTranslate?.data?.data["Days of admission"]}:</b> {item?.days_of_admission}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Partners />
        </>
    )
}

export default Leaderships
