import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import '../../styles/additional/NewItem.scss';
import BackImg from '../../assets/images/diving.webp';
import { fetchNewItem, fetchTranslate } from '../../api/Api';

function NewItem() {

    // router options

    const { id } = useParams();

    // data of news

    const dataTranslate = useQuery('translate', fetchTranslate);
    const dataNewItem = useQuery(['news', id], () => fetchNewItem(id));

    return (
        <div className="NewItem parent">
            <div className="c1 wrapper">
                <img src={BackImg} alt="back-img" className="back-img" />
            </div>
            <div className="c2 wrapper gap-1">
                <h1 className="title mbot-1">{dataTranslate?.data?.data["News"]}</h1>
                <div className="images gap-2">
                    {dataNewItem?.data?.data?.news_images?.map((item) => (
                        <img key={item?.id} src={item?.image} alt="img" className="img mtop-2 mbot-2" />
                    ))}
                </div>
                <p className="text date">{dataNewItem?.data?.data.pub_date?.slice(0, 10)}</p>
                <h1 className="big-text">{dataNewItem?.data?.data.title}</h1>
                <div className="text" dangerouslySetInnerHTML={{ __html: dataNewItem?.data?.data?.description }} />
            </div>
        </div>
    )
}

export default NewItem
