import React from 'react';
import { useQuery } from 'react-query';
import Partners from '../home/Partners';
import '../../styles/additional/Doping.scss';
import { useLocation } from 'react-router-dom';
import FileImg from '../../assets/images/pdf.png';
import BackImg from '../../assets/images/diving.webp';
import { DownloadIcon } from '../../assets/svgImages';
import { fetchDoping, fetchTranslate } from '../../api/Api';
import BottomPagination from '../pagination/BottomPagination';
import { useParentContext } from '../../contexts/ParentContext';

function Doping() {

    // router options

    const path = useLocation();

    // API options

    const dataTranslate = useQuery('translate', fetchTranslate);
    const dataDoping = useQuery(['doping', path.search], () => fetchDoping(path.search));

    // useContext hook options

    const { changeSimplePageNavigate } = useParentContext();

    return (
        <>
            <div className="Doping parent">
                <div className="c1 wrapper">
                    <img src={BackImg} alt="back-img" className="back-img" />
                </div>
                <div className="c2 wrapper gap-3">
                    <h1 className="title">{dataTranslate?.data?.data["Doping"]}</h1>
                    {dataDoping?.data?.data?.results?.map((item) => (
                        <div key={item?.id} className="text" dangerouslySetInnerHTML={{ __html: item?.description }} />
                    ))}
                    {dataDoping?.data?.data?.results?.map((item) => (
                        <div key={item?.id} className="cards gap-2" >
                            {item?.doping_files?.map((file) => (
                                <div key={file?.id} className="file_card round-1">
                                    <div className="img">
                                        <img src={FileImg} alt="file" />
                                    </div>
                                    <div className="desc pd-2 text">{file?.title}</div>
                                    <div className="download pd-2 gap-1">
                                        <div className="icon"><DownloadIcon /></div>
                                        <a href={file?.file} download={`${file?.title}`} className='link text'>{dataTranslate?.data?.data["download"]}</a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                    <BottomPagination dataLength={Math.ceil(dataDoping?.data?.data?.count / 10)} changeNavigate={changeSimplePageNavigate} />
                </div>
            </div >
            <Partners />
        </>
    )
}

export default Doping
