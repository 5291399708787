import React from 'react';
import { useQuery } from 'react-query';
import '../../styles/additional/Interview.scss';
import { fetchInterviews } from '../../api/Api';
import { Link, useLocation } from 'react-router-dom';
import BottomPagination from '../pagination/BottomPagination';
import { useParentContext } from '../../contexts/ParentContext';

function Interview() {

    // router options

    const path = useLocation();

    // API options

    const dataInterviews = useQuery(['interviews', path.search], () => fetchInterviews(path.search));

    // useContext hook options

    const { changeSimplePageNavigate } = useParentContext();

    return (
        <div className="Interview parent">
            <div className="wrapper gap-3">
                {dataInterviews?.data?.data?.results?.map((item) => (
                    <div key={item?.id} className="info">
                        <img src={item?.cover} alt="img" className="img round-2" />
                        <div className="texts gap-1">
                            <p className="date min-text">{item?.date}</p>
                            <Link to={`${item?.id}`} className="link text" onClick={() => window.scrollTo(0, 0)}>{item?.title}</Link>
                            <p className="desc min-text">{item?.description}</p>
                        </div>
                    </div>
                ))}
                <BottomPagination dataLength={Math.ceil(dataInterviews?.data?.data?.count / 10)} changeNavigate={changeSimplePageNavigate} />
            </div>
        </div>
    )
}

export default Interview
