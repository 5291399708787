import React from 'react';
import { useQuery } from 'react-query';
import Partners from '../home/Partners';
import '../../styles/additional/Doping.scss';
import { useLocation } from 'react-router-dom';
import FileImg from '../../assets/images/pdf.png';
import BackImg from '../../assets/images/diving.webp';
import { DownloadIcon } from '../../assets/svgImages';
import BottomPagination from '../pagination/BottomPagination';
import { fetchDocuments, fetchTranslate } from '../../api/Api';
import { useParentContext } from '../../contexts/ParentContext';

function Documents() {

    // router options

    const path = useLocation();

    // API options

    const dataTranslate = useQuery('translate', fetchTranslate);
    const dataDocuments = useQuery(['documents', path.search], () => fetchDocuments(path.search));

    // useContext hook options

    const { changeSimplePageNavigate } = useParentContext();

    return (
        <>
            <div className="Documents Doping parent">
                <div className="c1 wrapper">
                    <img src={BackImg} alt="back-img" className="back-img" />
                </div>
                <div className="c2 wrapper gap-3">
                    <h1 className="title">{dataTranslate?.data?.data["Documents"]}</h1>
                    {dataDocuments?.data?.data?.results?.map((item) => (
                        <div key={item?.id} className="infos gap-2">
                            <h1 className="name">{item?.name}</h1>
                            <div className="cards gap-1" >
                                {item?.files?.map((file) => (
                                    <div key={file?.id} className="file_card round-1">
                                        <div className="img">
                                            <img src={FileImg} alt="file" />
                                        </div>
                                        <div className="desc pd-2 text">{file?.name}</div>
                                        <div className="download pd-2 gap-1">
                                            <div className="icon"><DownloadIcon /></div>
                                            <a href={file?.item} download={"file"} target='_blank' className='link text'>{dataTranslate?.data?.data["download"]}</a>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                    <BottomPagination dataLength={Math.ceil(dataDocuments?.data?.data?.count / 10)} changeNavigate={changeSimplePageNavigate} />
                </div>
            </div>
            <Partners />
        </>
    )
}

export default Documents
