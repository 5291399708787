import React from 'react';
import { useQuery } from 'react-query';
import '../../styles/additional/Images.scss';
import { fetchGalleries } from '../../api/Api';
import { Link, useLocation } from 'react-router-dom';
import BottomPagination from '../pagination/BottomPagination';
import { useParentContext } from '../../contexts/ParentContext';

function Images() {

    // router options

    const path = useLocation();

    // API options

    const dataGalleries = useQuery(['photo-galleries', path.search], () => fetchGalleries(path.search));

    // useContext hook options

    const { changeSimplePageNavigate } = useParentContext();

    return (
        <div className="Images parent">
            <div className="wrapper gap-2">
                {dataGalleries?.data?.data?.results?.map((item) => (
                    <Link key={item?.id} to={`${item?.id}`} className='info' onClick={() => window.scrollTo(0, 0)}>
                        <img src={item?.images[0]?.image} alt="img" className="img round-2" />
                        <div className="texts round-2 pd-2 gap-1">
                            <div className="badge"></div>
                            <p className="text">{item?.title}</p>
                        </div>
                    </Link>
                ))}
            </div>
            <div className="wrapper c2">
                <BottomPagination dataLength={Math.ceil(dataGalleries?.data?.data?.count / 10)} changeNavigate={changeSimplePageNavigate} />
            </div>
        </div>
    )
}

export default Images
