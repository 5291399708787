import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import '../../styles/defaults/FullCalendar.scss';
import { fetchSports, fetchTranslate } from '../../api/Api';
import { Calendar } from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";

function FullCalendar() {

    // router options

    const navigate = useNavigate();

    // calendar options

    const [selectedDays, setSelectedDays] = useState([]);
    const [showCalendar, setShowCalendar] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setShowCalendar(false);
        });
    }, []);

    // API options

    const dataSports = useQuery('sports', fetchSports);
    const dataTranslate = useQuery('translate', fetchTranslate);

    // navigate options

    function changeNavigate() {
        navigate(`/sorevnovaniia?sport=&type=&gender=&date_year=${selectedDays[0]?.year}&date_month=${selectedDays[0]?.month}`)
    };

    return (
        <div className={`FullCalendar ${showCalendar && "FullHeight"}`}>
            <div className={`calendar ${showCalendar && "show_calendar"}`}>
                <button className="show_btn text pd-05 round-1" onClick={() => setShowCalendar(!showCalendar)}>{dataTranslate?.data?.data["calendar"]}</button>
                <Calendar
                    value={selectedDays}
                    onChange={setSelectedDays}
                    shouldHighlightWeekends
                    calendarClassName="responsive-calendar"
                />
                <div className="badges gap-1">
                    {dataSports?.data?.data?.map((item) => (
                        <div key={item?.id} className="badge min-text">{item?.name}</div>
                    ))}
                </div>
                {/* <p className="text mtop-2">{dataTranslate?.data?.data["upcoming"]}</p> */}
                {/* <p className="min-text mtop-1">Кубок мира по спортивному плаванию по водным видам спорта</p> */}
                {selectedDays?.length > 0 &&
                    <button className='list_btn scale-05 round-07 pd-05 text mtop-1' onClick={changeNavigate}>{dataTranslate?.data?.data["list_comp"]}</button>
                }
            </div>
        </div>
    )
}

export default FullCalendar
