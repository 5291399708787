import React from 'react';
import "swiper/css";
import "swiper/css/navigation";
import { useQuery } from 'react-query';
import '../../styles/home/Partners.scss';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from 'swiper/modules';
import { fetchPartners, fetchTranslate } from '../../api/Api';

function Partners() {

    // data of partners

    const { data } = useQuery('partners', fetchPartners);
    const dataTranslate = useQuery('translate', fetchTranslate);

    return (
        <div className="Partners parent">
            <div className="wrapper gap-3">
                <h1 className="sub-title">{dataTranslate?.data?.data["Partners"]}</h1>
                <Swiper
                    spaceBetween={15}
                    slidesPerView={3}
                    loop={true}
                    navigation={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Navigation]}
                    className="mySwiper"
                    breakpoints={{
                        768: {
                            slidesPerView: 5,
                            spaceBetween: 30,
                        },
                    }}
                >
                    {data?.data?.map((item) => (
                        <SwiperSlide key={item?.id}>
                            <a href={item?.url} target='_blank' className="img pd-1 round-07">
                                <img src={item.logo} alt="img" className="partner_img" />
                            </a>
                        </SwiperSlide>
                    ))}
                    {data?.data?.map((item) => (
                        <SwiperSlide key={item?.id}>
                            <a href={item?.url} target='_blank' className="img pd-1 round-07">
                                <img src={item.logo} alt="img" className="partner_img" />
                            </a>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )
}

export default Partners
