import React from 'react';
import { useQuery } from 'react-query';
import Partners from '../home/Partners';
import { useParams } from 'react-router-dom';
import '../../styles/additional/SportSmenItem.scss';
import BackImg from '../../assets/images/diving.webp';
import { fetchJudgesItem, fetchTranslate } from '../../api/Api';

function JudgesItem() {

    // router options

    const { id } = useParams();

    // API options

    const dataTranslate = useQuery('translate', fetchTranslate);
    const dataJudgesItem = useQuery(['judges-item', id], () => fetchJudgesItem(id));

    return (
        <>
            <div className="SportSmenItem parent">
                <div className="c1 wrapper">
                    <img src={BackImg} alt="back-img" className="back-img" />
                </div>
                <div className="c2 wrapper gap-3">
                    <h1 className="title">{dataTranslate?.data?.data["Referee"]}</h1>
                    <div className="infos gap-3">
                        <img src={dataJudgesItem?.data?.data?.image} alt="img" className="img round-1" />
                        <div className="texts gap-05">
                            <p className="big-text mbot-1">{dataJudgesItem?.data?.data?.name}</p>
                            <p className="text"><b>{dataTranslate?.data?.data["direction"]}:</b> {dataJudgesItem?.data?.data?.sport?.name}</p>
                            <p className="text"><b>{dataTranslate?.data?.data["Birth date"]}:</b> {dataJudgesItem?.data?.data?.birth_date}</p>
                            <p className="text"><b>{dataTranslate?.data?.data["Referee"]}:</b> {dataJudgesItem?.data?.data?.name}</p>
                            <p className="text"><b>{dataTranslate?.data?.data["Region"]}:</b> {dataJudgesItem?.data?.data?.region?.name}</p>
                            <p className="text"><b>{dataTranslate?.data?.data["Team"]}:</b> {dataJudgesItem?.data?.data?.team?.name}</p>
                        </div>
                    </div>
                </div>
            </div>
            <Partners />
        </>
    )
}

export default JudgesItem
