import React from 'react';
import { useQuery } from 'react-query';
import { fetchNews } from '../../api/Api';
import '../../styles/additional/News.scss';
import { Link, useLocation } from 'react-router-dom';
import BottomPagination from '../pagination/BottomPagination';
import { useParentContext } from '../../contexts/ParentContext';

function News() {

    // router options

    const path = useLocation();

    // data of news

    const dataNews = useQuery(['news', path.search], () => fetchNews(path.search));

    // useContext hook options

    const { changeSimplePageNavigate } = useParentContext();

    return (
        <div className="News parent">
            <div className={`wrapper gap-2 ${path.pathname == "/news" && "individual"}`}>
                <div className="cards gap-2">
                    {dataNews?.data?.data?.results?.map((item) => (
                        <Link to={`/media-novosti/${item?.id}`} key={item?.id} className="new_card pd-1-5 round-1 gap-05" onClick={() => window.scrollTo(0, 0)}>
                            <img src={item?.cover} alt="img" className="img round-05" />
                            <p className="text date mtop-05">{item?.pub_date?.slice(0, 10)}</p>
                            <p className="text">{item?.title}</p>
                            <div className="min-text" dangerouslySetInnerHTML={{ __html: item?.description }} />
                        </Link>
                    ))}
                </div>
                <BottomPagination dataLength={Math.ceil(dataNews?.data?.data?.count / 10)} changeNavigate={changeSimplePageNavigate} />
            </div>
        </div>
    )
}

export default News
